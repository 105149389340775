import { Banner } from "../../components/util/Banner";
import { RightImg } from "../../components/util/RightImg";
import { Heading } from "../../components/util/Heading";
import { CheckCard } from "../../components/util/CheckCard";
import { MetaForOthers } from "../meta/MetaForOthers";

export function Nepal() {
    return (
        <>
            <MetaForOthers
                title="Retreat in Nepal | Learn Yoga And Enjoy A Week Together | www.pankajyogpeeth.com"
                region="NP-NPR"
                placename="Nepal"
                position='28.210476194394715, 83.95781467854027'
                description="Yoga Retreat In Nepal: Enjoy Your Week With Yoga And Healthy Food And Sight Seeing With Accomodation."
            />
            <Banner img="../assets/images/retreat/nepal-retreat123.jpg"
                city={
                    <b>
                        Nepal Retreat
                    </b>
                }
                title="Yoga In Nepal"
            />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto px-0">
                        <Heading heading="Yoga Retreat in Nepal" />
                        <p className="text-center">
                            What does yoga retreat means? A yoga retreat is withdrawal to focus on the practice of yoga. A retreat can be solitary and/or local,but often involves a trip to a location that offers group yoga activities. some retreats around the world will offer you a luxury resorts and places with expensive charges. But with us you will experience the traditional practice of yoga with clean and affordable accomadation, where we will offer you some visitings to ashrams,discover beautiful landscapes and the sunrise in mountains. The retreat will help you to maintain the balance between your mind, body and prana. so, we will offer you a practice of yoga asana,meditation and lots of breath work they will bring the calmness and stillness in your mind.
                        </p>
                    </div>

                    <div className="mt-5">
                        <Heading heading="what do we Include In Retreat?" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/1.webp"
                            title="Yoga Retreat In Nepal"
                            data={<div className="row"> <CheckCard cols="col-12 col-sm-6" data={["Accomodation", "Food", "Traveling", "hearbal Tea", "Yoga Classes", "Sight Seeing", "Pranayam Class", "Meditation Class", "Single Room", "Shared Room"]} /> </div>}
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading="Our Yoga Journey During This Course" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/nepal-yoga.jpg"
                            title="Yoga Retreat In Nepal"
                            data={
                                <div className="px-5">
                                    <p>
                                        Discover Nepal with Pankaj Yogpeeth,
                                        It is the gateway to the Annapurna region where many a trekker finds his Shangri-La. Pokhara’s spellbinding beauty has been the subject of many travel writers. Its pristine air, the spectacular backdrop of the snowy peaks of the Annapurna Range and the serene Phewa, Begnas and Rupa Lakes, makes this destination ‘the Jewel of the Himalaya’. It is home to exceptionally warm and engaging people and is the starting point for treks into the Annapurna Himalaya, which dominates the skyline. The area along Fewa Tal, the largest lake in the valley, was first a camping ground for trekkers and has gradually developed into the tourist hub of this rapidly growing city.
                                    </p>
                                    <ul>
                                        <li>
                                            Venture to Fewa Lake, the largest and, some would say, the most beautiful of Nepal’s lakes. Here, you can enjoy boating on its placid waters and tuck into mouth-watering menus at one of the many restaurants that dot the lakeshore.
                                        </li>
                                        <li>
                                            Explore the Mahendra Cave, a large limestone cave famous for its stalactites and stalagmite rocks.
                                        </li>
                                        <li>
                                            Marvel at the World Peace Pagoda, situated on the southern fringe of Fewa Lake. It has four images of the Buddha facing in the four directions. The dome-shaped pagoda is an impressive sight. It is a great vantage point, offering spectacular views of the Annapurna.
                                        </li>
                                        <li>
                                            Travel to the old Pokhara Bazaar. This traditional market is also a colourful gathering place for an ethnically diverse group of traders. The temples and monuments of the bazaar showcase a close resemblance to the Newari architecture of the Kathmandu Valley. The old bazaar is also home to one of Pokhara’s most important shrines, the Bindhyabasini Mandir, a white dome-like structure atop a hill.
                                        </li>
                                        <li>
                                            Revel in the exceptional birdwatching opportunities in Pokhara.
                                        </li>
                                        <li>
                                            Grab your camera and head to Devil’s Fall, known locally as the Patale Chhango. This beautiful waterfall is located about 2 kilometres south-west of the Pokhara Airport on the Siddhartha Highway.
                                        </li>
                                        <li>
                                            Embark on scenic trekking adventures in the Annapurna region.
                                        </li>
                                        <li>
                                            Immerse yourself in the culture of the region by enjoying a day walk to nearby local communities
                                        </li>
                                    </ul>
                                </div>

                            }
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading=" Most Important - Food" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/food3.jpg"
                            heading={<h3>
                                <b>
                                    Most Important Thing is food That We Offers To Our Precious Guests:-
                                </b>
                            </h3>}
                            title="Yoga Retreat In Nepal"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Hygienic", "Healthy", "Protene", "Tasty"]}
                                    />
                                    <p className="mt-4">
                                        Food is the most important element in yoga retreat, this is directly related to our physical and mental strength. we constantly expend energy in various yogic activity, so we fill it with the delicious healthy organic food.These things have an effect on the physical and mental activities of the living being. physical health is also important as much as the mental health. We offer delicious vegan and vegetarian meals with lots of healthy food available according to season. we offers fresh juices and seasonal fruits salad.In retreat we also provide the traditional meaLs according to the locations Or the places we are conducting a yoga retreat. we also respect your needs of particular food and will help in arrange with all support.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>

                    <div className="mt-5" >
                        <Heading heading="Stay" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/room.jpg"

                            title="Yoga Retreat In Nepal"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Clean", "Hygienic", "Single/Shared", "Ac/Non-Ac"]}
                                    />
                                    <p className="mt-4">
                                        HOTEL Sri wellness Charming little hotel located on the South coast of Nepal, It will be our home during this Yoga retreat. Bedrooms surrounded by nature, Yoga hall with wonderful views, massage treatments, outdoor pool, ... they will help us to live with more presence, calm and well-being this stay. Sri Yoga Shala will welcome you to return to yourselves, to discover your essence and connect with it.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>


                </div>

            </div>
        </>

    )
}
