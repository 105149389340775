import Styles from "../../css/Custom.module.css";

export function Testimonials(props) {
    return (

        <div className={`${Styles.testimonials}`}>
            <div className={Styles.testimonialsImg}>
                <img src={props.img} alt={props.title} title={props.title} />
            </div>
            <span className={Styles.testimonialsHeading}>
                {props.heading}
            </span>
            <div className={Styles.testimonialsText}>
                {/* 226 character */}
                {
                    props.text
                }
            </div>
        </div>
    )
}
