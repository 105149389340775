import { useState } from "react";

function FileSelector({ icon, label, type, id, clickHandler, fileType }) {
  const [accept, setAccept] = useState(
    fileType === "image"
      ? "image/*"
      : fileType === "video"
      ? "video/mp4,video/x-m4v,video/*"
      : ""
  );

  return (
    <div className={`d-flex my-auto`} style={{ cursor: "pointer" }}>
      {icon}
      <label htmlFor={id} className="mx-1" style={{ cursor: "pointer" }}>
        {label}
      </label>
      <input
        type={type}
        className="d-none"
        id={id}
        accept={accept}
        onChange={(e) => clickHandler(e)}
      />
    </div>
  );
}

export default FileSelector;
