import React, { useEffect, useState } from "react";
import IndexTopCard from "../components/Index/IndexTopCard";

import { Fullpagetext } from "../components/util/Fullpagetext";
import OutLineCard from "../components/util/OutLineCard";
import Styles from "../css/Custom.module.css";
import { WhyPankaj } from "../components/util/WhyPankaj";
import { QuiltedImageList } from "../components/util/Gallery";
import { Heading } from "../components/util/Heading";
import { Courses } from "../components/util/Courses";
import { Testimonials } from "../components/util/Testimonials";

import { GiBed } from "react-icons/gi";

import { IoFastFoodOutline, IoBagHandleOutline } from "react-icons/io5";
import { InfoCard } from "../components/util/InfoCard";
import { NavLink } from "react-router-dom";
function Index() {
  const [userRec, setUserRec] = useState(0);

  useEffect(() => {
    const Data = setInterval(displayHello, 5000);
    function displayHello() {
      if (userRec < 6) {
        setUserRec((preVal) => preVal + 1);
      } else {
        console.log("hey");
        setUserRec(0);
      }
    }
    return () => clearTimeout(Data);
  }, [userRec]);

  console.log(userRec);

  return (
    <>
      <IndexTopCard number={userRec} previousNumb={userRec - 1} />
      <div className="container-fluid">
        <Heading heading="Upcoming Yoga Teacher Training Courses In Nepal" />
        <div className="row mt-4">
          <div className={`col-10 col-md-4 mx-auto ${Styles.mt1}`}>
            <OutLineCard
              href="/nepal/100-hours-yoga-teacher-training-course-in-nepal"
              title="100 Hours Yoga Teacher Training Course"
            />
          </div>
          <div className={`col-10 col-md-4 mx-auto ${Styles.mt1}`}>
            <OutLineCard
              href="/nepal/200-hours-yoga-teacher-training-course-in-nepal"
              title="200 Hours Yoga Teacher Training Course"
            />
          </div>
          <div className={`col-10 col-md-4 mx-auto ${Styles.mt1}`}>
            <OutLineCard
              href="/nepal/300-hours-yoga-teacher-training-course-in-nepal"
              title="300 Hours Yoga Teacher Training Course"
            />
          </div>
        </div>
      </div>
      <Fullpagetext
        varColor="var(--golden)"
        blend="color-burn"
        title="Pankaj Yogpeeth"
        text={[
          "Welcome to Pankaj Yogpeeth. Best Certified yoga teacher training course in Rishikesh, Kerala and In Nepal",

          "Pankaj Yogpeeth is founded with a strong conviction to share the traditional experience of yoga.",

          "We at our school strive to provide you with the best training experience. We have been training students and producing successful teachers for the past 5 years. Our school has been on the top ever since its establishment.",

          "we offer residential Authentic Hatha, Ashtanga Vinyasa, Meditation, Pranayama Multi-Style in  100-hour yoga teacher training course, 200 hour yoga teacher training in Rishikesh, 300 hour yoga teacher training course, comming soon 500 hour yoga teacher training course too, affiliated by Yoga Alliance USA.",

          " Our courses are specially designed to explore the physical, philosophical, spiritual and mental aspects of yoga.",

          "It's never too late to learn something new or never too early. However, let it be but the time is always right to learn yoga. So that's us. see you soon in Rishikesh",
        ]}
      />

      <div className={Styles.mtJumbo}>
        <div className="text-center">
          <span className={`${Styles.heading2} ${Styles.golden} `}>
            <Heading heading="Yoga Facts" />
          </span>
        </div>

        <div className={`${Styles.wrapper} mt-3`}>
          <InfoCard
            img="./assets/images/pankaj/quotes/krishna-arjun.jpg"
            title="― The Bhagavad Gita"
            textTitle="Best Yoga school in rishikesh"
            text={
              <h5 className={`px-2 text-center`}>
                Through selfless service,
                <br />
                you will always be fruitful and <br />
                find the fulfillment of your desires
              </h5>
            }
          />

          <InfoCard
            img="./assets/images/pankaj/quotes/pankaj-yogpeeth-a-true-self.jpg"
            textTitle="Best Yoga school in rishikesh"
            title="― A True Self"
            text={
              <h5 className={`px-2 text-center`}>
                You cannot always control what goes on outside.
                <br />
                But you can always control what goes on inside.
              </h5>
            }
          />

          <InfoCard
            img="./assets/images/pankaj/quotes/buddha.jpg"
            textTitle="Best Yoga school in rishikesh"
            title="― Ganga White"
            text={
              <h5 className="text-center">
                Just as treasures are uncovered from the earth, so virtue
                appears from good deeds, and wisdom appears from a pure and
                peaceful mind. To walk safely through the maze of human life,
                one needs the light of wisdom and the guidance of virtue
              </h5>
            }
          />
        </div>

        {/* <div className='d-flex justify-content-end px-5 mt-3'>
                        <ViewMoreTab  ="/teachers" name="View All" />
                    </div> */}
      </div>

      <WhyPankaj
        heading="Why Pankajyogpeeth"
        text={
          <>
            <h3>Why Yoga:</h3>
            <p>
              Yoga is essentially a spiritual discipline based on an extremely
              subtle science, which focuses on bringing harmony between mind and
              body. It is an art and scince of healthy living. The word
              <b>Yoga</b> is derived from the Sanskrit root <b>Yuj</b>, meaning
              <b> to join </b> or <b>to yoke</b> or <b>to unite</b>.As per Yogic
              scriptures the practice of Yoga leads to the union of individual
              consciousness with that of the Universal Consciousness, indicating
              a perfect harmony between the mind and body, Man &amp; Nature.
              According to modern scientists, everything in the universe is just
              a manifestation of the same quantum firmament. One who experiences
              this oneness of existence is said to be in yoga, and is termed as
              a yogi, having attained to a state of freedom referred to as
              mukti, nirvana or moksha. Thus the aim of Yoga is
              Self-realization, to overcome all kinds of sufferings leading to
              'the state of liberation' (Moksha) or ‘freedom’ (Kaivalya). Living
              with freedom in all walks of life, health and harmony shall be the
              main objectives of Yoga practice."Yoga” also refers to an inner
              science comprising of a variety of methods through which human
              beings can realize this union and achieve mastery over their
              destiny.Yoga, being widely considered as an ‘immortal cultural
              outcome’ of Indus Saraswati Valley civilization – dating back to
              2700 B.C., has proved itself catering to both material and
              spiritual upliftment of humanity.Basic humane values are the very
              identity of Yoga Sadhana.
            </p>
            <b>Why Pankaj Yogpeeth ?</b>
            <p>
              Pankaj Yogpeeth shares the traditional Yoga which is SAFE and
              driven by best yoga teachers in India. All our courses are Yoga
              Alliance USA certified courses. The whole intention of starting
              Pankaj Yogpeeth is sharing the Yoga experience we received from
              our teachers. All our teachings are not only for the sake of
              certification but for the great purpose of raising the awareness
              of whole humanity. We want to make sure that whoever practice yoga
              his/her life should a blissful life. At Pankaj yogapith we are
              committed to providing you with the most cost effective courses
              available. Our all inclusive courses provide you with quality
              services at an affordable price.
            </p>
          </>
        }
        infoCardData={[
          [
            <IoFastFoodOutline fontSize="122px" color="black" />,
            <h3>Food </h3>,
            <p>
              Food is the most important element for our students, this is
              directly related to their physical and mental strength. We
              constantly expend energy in various yogic activity, so we fill it
              with the delicious healthy organic food.
            </p>,
          ],
          [
            <GiBed fontSize="170px" color="black" />,
            "Accomodation",
            "We provides clean and hygienic room. after doing yoga they can rest here and sleep in nights our rooms attached with toilet and bathrooms with full privacy.",
          ],
          [
            <IoBagHandleOutline fontSize={170} color="black" />,
            "Kit",
            "Pankaj Yogpeeth provides a starter kit to our students.",
          ],
        ]}
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={`${Styles.mtJumbo}`}>
              <div className="mb-3">
                <Heading heading="Gallery" />
              </div>
            </div>
          </div>

          <div className="col-11 mx-auto px-1">
            <QuiltedImageList />
          </div>
        </div>
      </div>

      <div className="col-12">
        <Heading heading="Courses" />
      </div>

      <Courses
        img="./assets/images/pankaj/Nepal/PankajYogpeeth (80).jpg"
        dir="left"
        heading="100 hours yoga teacher Training"
        text={[
          "Pankaj Yogpeeth Conduct Certified 100  Hours Yoga Teacher Training Course In Rishikesh, Kerala And In Nepal.",
          "Join Our Course As Soon As Possible. For More Details Please Choose Valid Locations",
          <div className="d-flex justify-content-around flex-wrap">
            <NavLink to="/rishikesh"> Rishikesh </NavLink>
            <NavLink to="/kerala"> Kerala </NavLink>
            <NavLink to="/nepal"> Nepal </NavLink>
          </div>,
        ]}
      />

      <Courses
        img="./assets/images/pankaj/Nepal/PankajYogpeeth (9).jpg"
        heading="200 hours yoga teacher Training"
        text={[
          "Pankaj Yogpeeth Conduct Certified 200  Hours Master Level Yoga Teacher Training Course In Rishikesh, Kerala And In Nepal.",
          "Join Our Course As Soon As Possible. For More Details Please Choose Valid Locations",

          <div className="d-flex justify-content-around flex-wrap">
            <NavLink to="/rishikesh">Rishikesh</NavLink>
            <NavLink to="/kerala">Kerala</NavLink>
            <NavLink to="/nepal">Nepal</NavLink>
          </div>,
        ]}
      />

      <Courses
        img="./assets/images/pankaj/teachers/a.jpeg"
        dir="left"
        heading="300 hours yoga teacher Training"
        text={[
          "Pankaj Yogpeeth Conduct Certified 300  Hours Yoga Teacher Training Course In Rishikesh, Kerala And In Nepal.",
          "Join Our Course As Soon As Possible. For More Details Please Choose Valid Locations",
          <div className="d-flex justify-content-around flex-wrap">
            <NavLink to="/rishikesh"> Rishikesh </NavLink>
            <NavLink to="/kerala"> Kerala </NavLink>
            <NavLink to="/nepal"> Nepal </NavLink>
          </div>,
        ]}
      />

      <Courses
        img="./assets/images/pankaj/kerala/PankajYogpeeth new.jpeg"
        heading="500 hours yoga teacher Training"
        text={[
          "Pankaj Yogpeeth Going To Lunch 500 Hours Yoga Teacher Training Course For Those Who's Want To DIve Into More Deeper Knowledge OF Yoga.",
          <h1 className="text-center text-primary">Comming Soon</h1>,
        ]}
      />

      <div className={`${Styles.mtJumbo}`}>
        <Heading heading="Testimonials" />
      </div>

      <div className={`${Styles.testimonialsContainer}`}>
        <Testimonials
          img="./assets/images/pankaj/testimonials/Rebecca.jpg"
          title="Best Yoga School In Rishikesh"
          heading="Rebecca"
          text={
            <div>
              <p>
                I meet Pankaj and all his beautiful team during my 200hs TTC in
                India. When I knew he opened his own school, I directly knew I
                will do my 300hs TTC with him: because I was sure I will find
                the knowledge I was looking for.
              </p>
              <p>
                Now that I came back from my 300hs TTC (in Nepal Pokhara) I know
                I was right. I meet incredible teachers, so generous in there
                way of teaching. I was in a great location: the food was just
                soooooo good and the people of the Hostal where we stayed alway
                provided us what we needed. I always felt in a secure place,
                surrounding by great energies. I never felt something was
                missing. Pankaj and all the teachers was always ready to listen
                us or any of our demands.
              </p>
              <p>
                I feel very blessed to have being able to join the TTC to learn
                with them. My heart is full of there love and knowledge.
              </p>
              <p>I definitely recommend this school !!</p>
            </div>
          }
        />
        <Testimonials
          img="./assets/images/pankaj/testimonials/Vanessa.jpg"
          title="Best Yoga School In Kerala"
          heading="Vanessa S"
          text="I was participating on the online 50 hours Yin Yoga TTC and it was simply amazing. In knew Pankaj before as he has been my Hatha Yoga teacher for my 200hr TTC in Nepal 2017. Both times were life-changing experiences. I really love that Pankaj really looks at each single person even online and gives helpful instructions. However, Pankaj and his fellow teachers are not just instructors, they are real teachers. Thank you so much for sharing your time, wisdom and knowledge about traditional yoga. We could ask you anything anytime and your support was the best. I can highly recommend this TTC or any other classes at Pankaj Yogpeeth. Hari Om🙏🏼"
        />
        <Testimonials
          img="./assets/images/pankaj/testimonials/lilit.jpg"
          title="Best Yoga School In Nepal"
          heading="Iris Nina"
          text="Pankaj is a great yoga teacher, always supporting your individual yoga progress. I love the Hatha Yoga lessons and the pranayama. I feel so much better after the training. I recommend 100%."
        />

        <Testimonials
          img="./assets/images/pankaj/testimonials/saud.jpg"
          title="Best Yoga School In Nepal"
          heading="Lilit G"
          text={
            <div>
              <p>
                I had the pleasure of learning so much from the outstanding
                teachers of Pankaj Yogpeeth.
              </p>
              <p>
                I experienced classical Hatha Yoga and Ashtanga Vinyasa training
                with Pankaj Sadana. He is very skillful and an excellent
                teacher. His training method is very comprehensive and easy to
                follow. His classes are well designed with a balanced increase
                of efforts and a lot of focus on the breathing which help to
                uncover the potential of the body at it’s fullest. He is the
                kind one would want to learn from and I absolutely enjoyed his
                classes and still do so even after the training course.
              </p>
              <p>
                Yoga philosophy classes were conducted by Swami Taponidhi. I
                love his systematic approach. Each topic was very thoroughly and
                extensively explained and he has got some beautiful “life lesson
                type” stories to tell, which could affect the quality of one’s
                life in a good way :). He has answers to a lot of questions.
                Can’t wait to experience his magical Yoga Nidra to which I’m
                signed up at the end of this month.
              </p>
            </div>
          }
        />

        <Testimonials
          img="./assets/images/pankaj/testimonials/rafela.jpg"
          title="Best Yoga School In Nepal"
          heading="Rafaela"
          text={
            <div>
              <p>
                The yoga training course with pankaji was very good. Somethings
                can get better, but the mostly is super good. I recommend for
                everyone who wants to learn more about yoga and india culture.
              </p>
            </div>
          }
        />

        <Testimonials
          img="./assets/images/pankaj/testimonials/Iris.jpg"
          title="Best Yoga School In Nepal"
          heading="Saud Adventrous"
          text={
            <div>
              <p>
                I never practiced yoga before coming to India. I was a bit
                hesitant since I never wanted to teach yoga, and only wanted a
                break away from work and life. Pankaj was an amazing mentor, who
                introduced me as a beginner to a meaningful experience, and in
                his class I got to meet amazing friends, with wonderful stories.
              </p>
              <p>
                It's a memorable experience in Varkala, and I would definitely
                recommend it to friends and family!
              </p>
            </div>
          }
        />
      </div>
    </>
  );
}

export default Index;
