import { NavLink } from "react-router-dom"

export function Error() {

    return (
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-12 col-md-7 col-lg-6 mx-auto mt-2 text-center">
                    <div className="">
                        <img src="./assets/images/error/Meditation.gif"
                            alt="Pankaj Yogpeeth One Of The Best Institute In 
                        Rishikesh"
                            title="Yoga In Rishikesh"
                            className="img-fluid" />
                    </div>

                </div>
                <div className="col-12 col-md-5 col-lg-6 m-auto">
                    <div className="float-md-start">
                        <h3>
                            Something Went Wrong?
                        </h3>
                        <p>
                            Pankaj Yogpeeth Helping Team Always Here To Assist You.
                        </p>
                        <p>
                            What'sapp Us Any Time <br /> <a href="tel:+">
                                +91 9997402794
                            </a>
                        </p>
                        <h1>
                            Or
                        </h1>
                        <p>
                            Write Us Mail
                            <br />
                            <a href="mailto:pankajyogpeeth@gmail.com">
                                pankajyogpeeth@gmail.com
                            </a>
                        </p>

                        <button className="btn btn-sm">
                            <NavLink to="/">
                                <h3>Go Back Home</h3>
                            </NavLink>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
