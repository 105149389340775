import { Heading } from "./Heading";
import { RightImg } from "./RightImg";

export function DailySchedule({ img }) {
  return (
    <div className="row mt-3">
      <div className="col-12">
        <Heading heading={<b> Our daily schedule </b>} />
      </div>
      <div className="col-12 col-md-12 text-center mx-auto">
        <RightImg
          dir="ltr"
          img={img || "../assets/images/index/namaste.jpg"}
          title="Yoga School In Kerala"
          tableData={[
            ["Start Time", "End Time", "Daily Schedule"],
            ["5:45 am", "06:00 am", "Wake up and ayurvedic tea"],
            ["6:15 am", "06:45 am", "Hatha Yoga"],
            ["08:00 am", "09:00 am", "Pranayama"],
            ["09:00 am", "10:30 am", "Breakfast"],
            ["10:30 am", "12:00 pm", "Philosophy"],
            ["12:00 pm", "01:00 pm", "Alignment / Teaching Methodology"],
            ["01:00 pm", "03:00 pm", "Lunch"],
            [
              "03:15 pm",
              "04:15 pm",
              "Mudras / Ayurveda / Chair Yoga / Laughing",
            ],
            ["04:30 pm", "06:00 pm", "Ashtanga Vinyasa"],
            ["06:15 pm", "07:15 pm", "Yoga Nidra / Meditation / Bhakti Yoga"],
          ]}
        />
      </div>
    </div>
  );
}
