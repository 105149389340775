import { RightImg } from "../../components/util/RightImg";
import { Banner } from "../../components/util/Banner";
import { useEffect } from "react";
import { CheckCard } from "../../components/util/CheckCard";
import { FloatingButton } from "../../components/util/FloatingButton";

function YinYoga() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Banner
              img="../assets/images/pankaj/yinYoga1.jpg"
              city={
                <p className="display-1">
                  <u>Yin Yoga</u>
                </p>
              }
            />
          </div>
        </div>

        {/* Yin Yoga */}
        <div className="row">
          <div className="col-12 mt-5 text-center">
            <b className="display-3"> What Is Yin Yoga? </b>
          </div>
          <div className="col-12 col-md-8 mx-auto text-center">
            <p>
              We will explore the various expressions of yin throughout this
              programme, including yin yoga, fascia universe, functional
              approach to the practise, somatic embodiment, sound healing
              meditation, and yoga nidra, in order to support the release of
              mental patterns, emotional swings, and physical tensions that are
              ingrained in our bodies.
            </p>
            <p>
              This Yin Deepening Program will be jam-packed with embodied
              practises related to the fascia, the soma, and techniques to
              reduce the amount of activity in our nervous system as a pathway
              to healing.
            </p>
          </div>

          <div className="col-12 col-md-8 mx-auto text-center mt-4">
            <div className="display-3 text-center">
              <span>Who is Yin yoga for?</span>
            </div>
            <p>
              If you're exhausted and in need of energy or if you're
              overstimulated and have too much energy, yin yoga is for you.
            </p>
            <p>
              Our world continually bombards us with stimuli, keeping our minds
              occupied as they attempt to digest everything that is being fed to
              them. It doesn't matter if the information is useful or useless;
              the mind still needs to process it. Over time, we acquire
              accustomed to that level of stimulation and begin to seek it when
              things are quiet. As a result, we find ourselves searching and
              looking for things—anything would do as long as it fills the
              holes.
            </p>
            <p>
              This aspect of keeping ourselves active is catered to in any
              dynamic yoga style. Active exercise may cause the mind to quiet
              down, but we are still satisfying the part of ourselves that seeks
              intensity and needs to be stimulated. We just so happen to have a
              healthier stimulus now! I'm not advising you to stop practising
              dynamic yoga; just attempt to strike a balance between them all.
              The practise of Yin yoga is a fantastic method to do that.
            </p>
          </div>

          <div className="col-12 text-center mt-4 display-3">
            Yin Yoga 200 hours TTC
          </div>

          <div className="col-12 text-center mt-4">
            <div className="card shadow border-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6 m-auto">
                    <img
                      src="assets/images/pankaj/yinYogaRishikesh.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-3 m-md-auto">
                    <div className="d-flex flex-column">
                      <span
                        className="display-5 text-primary"
                        style={{ fontWeight: 800 }}
                      >
                        <u>Yin Yoga Kerala</u>
                      </span>
                      <span className="display-6">
                        December 4 to December 29
                      </span>
                      <span>
                        <h4>December 4 - Opening Ceremony</h4>
                      </span>
                      <p>
                        The connective tissues, also referred to as yin tissues,
                        are the focus of yin yoga. We hold the positions for a
                        longer period of time because slow, constant pressure on
                        connective tissue has better results. The body will
                        respond by lengthening and strengthening the connective
                        tissue if you gently stretch it by holding a yin pose
                        for a prolonged period of time in this manner. This is
                        what you desire.
                      </p>
                      <p>
                        <h5>
                          <b>Dec 4 - Opening Ceremony</b>
                        </h5>
                        <h5>
                          <b>
                            5PM - 7PM IST <br /> (introducing of each other and
                            becoming a family)
                          </b>
                        </h5>
                        <h5>
                          <b>
                            Dec 29 - 10AM - 11.30AM Exam Time <br /> 4PM -
                            5.30PM - Closing Ceremony
                          </b>
                        </h5>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Yin Yoga */}
      </div>

      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 my-3">
            <div className="text-center">
              <div className="display-6">
                Topics that we covered during this course!
              </div>
            </div>
          </div>

          <CheckCard
            height={"calc(40px + 2.7vw)"}
            cols="col-12 col-md-5 col-lg-4 col-xl-3"
            data={[
              "TCM foundations of Yin/Yang theory",
              "Fascia anatomy",
              "Joint anatomy",
              "Yin as therapeutic use",
              "Yin Yoga poses 36+ detailed",
              "Chinese Medicine Meridians",
              "5 elements and use",
              "Myofascial chains",
              "Yin Yoga asana Sequencing",
              "Introduction of Yin Yoga",
              "Self - inquiry overview , contemplation, the natural state",
              "The Divine Feminine",
              "How to teach Yin Yoga with knowing the principles",
            ]}
          />
        </div>

        {/*  */}
        <div className="row mt-5">
          <div className="col-12">
            <div className="display-5 text-center">Our Daily Schedule</div>
          </div>
          <RightImg
            img="../assets/images/pankaj/vertical/pankajyogpeeth (1).jpg"
            tableData={[
              ["Start Time", "End Time", "Daily Schedule"],
              ["05:00 am", "06:00 am", "Morning Tea"],
              ["06:00 am", "07:00 am", "Meditation Pranayama"],
              ["07:30 am", "08:30 am", "Hatha Warm Up"],
              ["09:00 am", "10:00 am", "Breakfast"],
              ["10:15 am", "11:15 am", "Anatomy"],
              ["11:30 am", "12:30 pm", "Theory"],
              ["12:45 pm", "01:45 pm", "Theory"],
              ["01:50 pm", "03:50 pm", "Lunch"],
              ["04:00 pm", "05:00 pm", "Practical"],
              ["05:30 pm", "06:30 pm", "Practice"],
              ["06:35 pm", "07:00 pm", "Doubts Clearing Timeout"],
              ["07:00 pm", "08:00 pm", "Dinner"],
            ]}
          />
        </div>

        <div className="row">
          <div className="col-12 mt-5">
            <div className="text-center mt-5">
              <span className="display-2">Book Your Seat Now</span>
            </div>
            <div className="text-center d-flex justify-content-center flex-wrap">
              <a
                href="https://www.paypal.me/pankajsadana57"
                className="mt-2 mx-2"
                target="__new_tab"
              >
                <h2>Single 1250$</h2>
              </a>

              <a
                href="https://www.paypal.me/pankajsadana57"
                className=" mt-2 mx-2"
                target="__new_tab"
              >
                <h2>Share 950$</h2>
              </a>
            </div>
          </div>
        </div>
      </div>
      <FloatingButton
        title3="Single"
        title2="Shared"
        price3="1250 $"
        price2="950 $"
        url3="https://www.paypal.me/pankajsadana57"
        url2="https://www.paypal.me/pankajsadana57"
        book="Book"
        now="Now"
      />
    </>
  );
}
export default YinYoga;
