import Styles from "../../css/Custom.module.css";

export function ResponsiveTable(props) {

    return (
        <div className="d-flex mx-auto">
            <table className={`${Styles.responsiveTable} table  px-0 mx-auto`}>
                <thead>
                    <tr>
                        <th>
                            <span className="m-auto text-center">
                                Course
                            </span>
                        </th>
                        <th>
                            <span className="m-auto text-center">
                                Dates
                            </span>

                        </th>
                        <th>
                            <span className="m-auto text-center">
                                Fee
                            </span>

                        </th>
                        <th>
                            <span className="m-auto text-center">
                                Occupied
                            </span>

                        </th>

                        <th>
                            <span className="m-auto small text-center">
                                Book Now
                            </span>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item, index) =>
                        <tr key={index}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                            <td>{item[2]}</td>
                            <td>{item[3]}</td>
                            <td>
                                <a href="https://www.paypal.me/pankajsadana57">
                                    {item[4]}
                                </a>
                            </td>
                        </tr>
                    )}


                </tbody>
            </table>
        </div>
    )
}
