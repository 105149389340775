import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import style from "../../css/PostCard.module.css";
import { BlogsSort } from "../../screens/blogs/BlogsSort";
import { PostCard } from "./PostCard";

export function MultiplePostCard() {
  const [postData, setPostData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    if (localStorage.getItem("userId")) {
      await axios
        .get(`getBlogs`)
        .then((res) => {
          setPostData(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      await axios
        .get("getBlogs")
        .then((res) => {
          console.log(res.data);
          setPostData(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  };

  // localStorage.clear();
  loading ? console.log(loading) : postData.map((item) => console.log(item));

  useEffect(() => {
    getData();
  }, []);

  // console.log(myLike);
  // `/blogs/${item.id}/${item.title.replace(/ /g, "_")}`;

  return loading ? (
    "...loading"
  ) : (
    <div className={style.flexWrapper}>
      <div className={style.postCardWrapper}>
        {postData.map((item, index) => (
          <PostCard
            key={index}
            text={item.description}
            img={"http://localhost:8000/blogs/" + item.file}
            profilePic={"assets/images/chakra/chakra.webp"}
            fileType={item.fileType}
            username={"Shivam Mittal"}
            occupation={"Yogi"}
            title={item.subject}
            date={item.created_at}
            postId={item.id}
            userId={item.userId}
            like={localStorage.getItem("userId") ? item.like : ""}
          />
        ))}
      </div>
      <div>
        <BlogsSort />
      </div>
    </div>
  );
}
