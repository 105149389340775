import { Heading } from "./Heading";
import { RightImg } from "./RightImg";

export function Accomodation({ inSide }) {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Heading heading={<b> Accomodation </b>} />
      </div>
      <div className="col-12">
        <RightImg
          cols="col-md-12 col-lg-6"
          cols1="col-md-12 col-lg-6"
          img={
            inSide
              ? "../../assets/images/pankaj/accomodation/pankajyogpeethaccomodation.jpeg"
              : "../assets/images/pankaj/accomodation/pankajyogpeethaccomodation.jpeg"
          }
          data={
            <>
              <p>
                The place where we gonna stay during our yoga teacher training
                course, the place really have the comfortable rooms to stay,and
                a beautiful suurounding with the nature,having lots of palm
                trees and a feel of a little forest near you.The accomadation we
                gonna stay is very close to the main beach in varkala and the
                very famous beach called black beach. Also the reach to the each
                beach is by walkable distance. And the most interesting part of
                our yoga retreat place is located on varkala beach. And we also
                have some facilities and refreshing spa to rejuvenuate your soul
                and nourish your body with a massage.
              </p>
              <p>The yoga place is located near the varkal beach.</p>
            </>
          }
          dir="rtl"
        />
      </div>
    </div>
  );
}
