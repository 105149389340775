import { useEffect } from "react";
import Styles from "../../css/Custom.module.css";
import { Steppers } from "../../components/util/Steppers";
import { BsFacebook, BsLinkedin, BsInstagram, BsTelephone } from 'react-icons/bs';
import { ImYoutube2 } from "react-icons/im";
import { Heading } from "../../components/util/Heading";

import { FaWhatsapp } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

import { MetaForOthers } from "../meta/MetaForOthers";

export function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <MetaForOthers
                title="Contact Us Pankaj Yogpeeth | 
                100 Hours Teacher Training Course In Rishikesh |
                www.pankajyogpeeth.com"
                region="IN-RK"
                placename="Rishikesh"
                position='9.788839240475891, 76.66260033484168'
                description="Happy To Help You! If You Have Any Query You Can Ask."
            />
            <Steppers navlinks={[
                ["/contact-us", "Contact-us", "Contact Us"]
            ]} />

            <div className={Styles.contactus}>
                <div className={Styles.first}></div>
                <div className={Styles.second}></div>
                <div className={`${Styles.textContainer}`}>
                    <span className={Styles.text}> Contact Us </span>

                    <div className={`w-100 d-flex justify-content-center ${Styles.zIndex1}`}>


                        <a href="https://youtube.com/channel/UCCkkqKK9khuApsf-qfB5wcQ" title="Join Us On Facebook">
                            <ImYoutube2 className={Styles.icon} />
                        </a>

                        <a href="https://www.facebook.com/pankajyogpeeth/" title="Join Us On Facebook">
                            <BsFacebook className={Styles.icon} />
                        </a>

                        <a href="linkedin.com/in/pankaj-yogpeeth">
                            <BsLinkedin className={Styles.icon} />
                        </a>

                        <a href="https://www.instagram.com/invites/contact/?i=vjacv30t4l9t&utm_content=7v6uhhw">
                            <BsInstagram className={Styles.icon} />
                        </a>

                        <a href="tel:+919997402794">
                            <BsTelephone className={Styles.icon} />
                        </a>


                    </div>

                </div>
            </div>

            <div className="container-fluid">
                <div className="row px-2">
                    <div className="col-12 mt-5">
                        <Heading heading={<b>Contact Us</b>} />
                    </div>
                    <div className="col-12 mx-auto px-4">

                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="my-2">
                                    <h3>
                                        <b> Address </b>
                                    </h3>
                                </div>

                                <div
                                    className="d-flex flex-row flex-wrap ">
                                    <div className="my-3">
                                        <b>
                                            Rishikesh Address
                                        </b>

                                        <h4> Pankaj Yogpeeth </h4>
                                        <span>
                                            Jyotirgamaya Vatika Swarg Ashram Road,
                                            <br />
                                            Swarg Ashram Rishikesh
                                            <br />
                                            <b>
                                                Uttarakhand 249304
                                            </b>
                                        </span>

                                    </div>
                                    <div className="w-100">
                                        <hr />
                                    </div>

                                    <div className="my-3">

                                        <b>
                                            Kerala Address
                                        </b>
                                        <h4> Pankaj Yogpeeth </h4>
                                        <span>
                                            Savitri INN Cottage &amp; Retreats
                                            <br />
                                            Thirumbadi Road Varkala
                                            <br />
                                            <b>
                                                Kerala 695141
                                            </b>
                                        </span>

                                    </div>
                                    <div className="w-100">
                                        <hr />
                                    </div>

                                    <div className="my-3">

                                        <b>
                                            Nepal Address
                                        </b>
                                        <h4> Pankaj Yogpeeth </h4>
                                        <span>
                                            Hotel Tulsi
                                            <br />
                                            14 Street Pokhra
                                            <br />
                                            <b>
                                                Nepal 33700
                                            </b>
                                        </span>

                                    </div>
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <h3>
                                        <b>
                                            Contact Details:-
                                        </b>
                                    </h3>
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <div className="d-flex flex-wrap">
                                            <div>

                                                <a href="tel:+91 9997777457" className="d-flex mx-2">
                                                    <FaWhatsapp
                                                        color="black"
                                                        size="26" />
                                                    &nbsp;
                                                    <h4> +91 9997777457 </h4>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="tel:+91 9997402794" className="d-flex mx-2">
                                                    <FaWhatsapp
                                                        color="black"
                                                        size="26" />
                                                    &nbsp;
                                                    <h4> +91 9997402794 </h4>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <a href="mailto:pankajyogpeeth@gmail.com"
                                                className="d-flex mx-2">
                                                <MdAlternateEmail size="26"
                                                    color="black"
                                                />
                                                &nbsp;

                                                <h3>
                                                    pankajyogpeeth@gmail.com
                                                </h3>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
