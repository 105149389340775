import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { RightImg } from "../../components/util/RightImg";

import { Collapse } from "../../components/util/Collapse";
import { Steppers } from "../../components/util/Steppers";

import { ResponsiveTable } from "../../components/util/ResponsiveTable";
import { TinyBanner } from "../../components/util/TinyBanner";

import { MetaRishikesh } from "../meta/MetaRishikesh";
import { QuestionAnswer } from "../../components/util/QuestionAnswer";

import { useEffect } from "react";
import { DailySchedule } from "../../components/util/DailySchedule";
import WhatDoWeOffer from "../../components/util/WhatDoWeOffer";
import { Expect } from "../../components/util/Expect";
import { Accomodation } from "../../components/util/Accomodation";
import { Food } from "../../components/util/Food";

export function RishikeshTwoHundredHours() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaRishikesh
        title="200 Hours Teacher Training Course In Rishikesh| Best Yoga"
        description="200 Hours TTC In Rishikesh. In 200 Hours: 
                deeper knowledge of Meditation, Phylosophy, Pranayama, Hatha Yoga, Shatkarma, Asana And Much More"
        position="28.210476194394715, 83.95781467854027"
        placename="Rishikesh"
        ogUrl="https://www.pankajyogpeeth.com/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh"
      />
      <div className="container-fluid">
        <Steppers
          navlinks={[
            [
              "/rishikesh",
              "Rishikesh",
              "rishikesh Yoga Teacher Traning Course",
            ],
            [
              "/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh",
              "200 Hours Yoga TTC",
              "200 Hours Yoga Teacher Training Course In Rishikesh",
            ],
          ]}
        />

        <div className="row">
          <div className="col-12 px-0">
            <Banner
              img="../assets/images/pankaj/rishikesh/pankajyogpeeth.jpg"
              city={
                <h1 className="text-center">
                  <b>200 Hours Yoga Teacher Training Courses</b>
                </h1>
              }
              title="200 Hours Yoga Teacher Training Course In Rishikesh"
            />
          </div>

          <div className="col-12 col-md-12 mx-auto px-4">
            <Heading
              heading={
                <b>
                  200 HOUR YOGA TEACHER TRAINING IN RISHIKESH, INDIA - OVERVIEW
                </b>
              }
            />

            <div className="mt-2">
              <RightImg
                title="200 Hours Yoga Teacher Training Course In Rishikesh"
                img="../assets/images/index/namaste.jpg"
                data={
                  <>
                    <p>
                      200 Hours Yoga Teacher Training in Rishikesh, India is a
                      great opportunity for those who are looking for deepen
                      there practice or the students who really want to know
                      yoga.200 hours Yoga Teacher Training course offered by
                      pankaj yogpeeth, is Yoga Alliance USA certified and is
                      suited for both beginner and intermediate level yoga
                      practioners.
                    </p>

                    <p>
                      The Course is a 26-days YTTC that covers the 200 hours
                      yoga teacher training course syllabus. Upon completion of
                      the 200 hours yoga teacher training Course, students will
                      got the yoga alliance certificate from Pankaj Yogpeeth and
                      become certified yoga teachers.
                    </p>

                    <p>
                      Our 200 Hour Yoga TTC course is designed specifically to
                      heal your body and fill your mind with knowledge of
                      traditional yoga practices.
                    </p>
                    <p>
                      The school serves organic and Sattvic meals that will help
                      you become more flexible and better at your yoga
                      practices. Daily schedule is designed in such a way that
                      you will be able to incorporate yoga and meditation in
                      your daily life even after leaving our schools.
                    </p>
                  </>
                }
                dir="ltr"
                imgClass="d-none d-md-flex"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-11 col-md-10 mx-auto">
            <Heading
              heading={
                <b>200 Hours Yoga Teachers Training Course In Rishikesh</b>
              }
            />
          </div>
          <div className="row mx-auto">
            <div className="col-12 col-md-4">
              <Collapse
                id="phy1"
                heading="Phylosophy"
                text={[
                  <ul>
                    <li> Introduction </li>
                    <li>Origin of Yoga Origin of Yoga Origin of Yoga</li>
                    <li> Different Paths of Yoga </li>
                    <li> Ashtanga Yoga(8 Limbs) </li>
                    <li> Yama </li>
                    <li> Niyama </li>
                    <li> Asana </li>
                    <li> Pranayama </li>
                    <li> Pratyahara </li>
                    <li> Dharana </li>
                    <li> Dhyana </li>
                    <li> Samadhi </li>
                    <li> Hatha Yoga </li>
                    <li> Obstacles in Yoga </li>
                    <li> Panch Kosha </li>
                    <li> Annamaya Kosha </li>
                    <li> Pranamaya Kosha </li>
                    <li> Manomaya Kosha </li>
                    <li> Jnanamaya Kosha </li>
                    <li> Anandamaya Kosha </li>
                    <li> Sankhya Darshan </li>
                    <li> Vedanta Darshan </li>
                  </ul>,
                ]}
              />
            </div>
            <div className="col-12 col-md-4">
              <Collapse
                id="phy2"
                heading="Asana"
                text={[
                  <ul>
                    <li>Hatha Yoga</li>
                    <li>Alignment And Adjustment</li>
                    <li>Ashtanga Vinyasa</li>
                  </ul>,
                ]}
              />
            </div>
            <div className="col-12 col-md-4">
              <Collapse
                id="phy3"
                heading="Meditation"
                text={[
                  <>
                    <p>
                      Mantra chanting is an effective way to align your
                      vibrations and awareness with the aspects of the self and
                      infinite. It is a great for improving concentration,
                      attention, focus and acts an effective method to release
                      emotions.
                    </p>

                    <ul>
                      <li>Om chanting</li>
                      <li>MahaMrityunjaya Mantra</li>
                      <li>Gayatri Mantra</li>
                      <li>Guru Vandana (prayer to Guru)</li>
                      <li>Prayer before class &amp; Meal</li>
                    </ul>
                  </>,
                ]}
              />
            </div>
            <div className="col-12 col-md-4">
              <Collapse
                id="pranayama1"
                heading="Pranayama"
                text={[
                  <>
                    <p>
                      Pranayama is generally defined as breath control. Although
                      this interpretation may seem correct in view of the
                      practises involved, it does not convey the full meaning of
                      the term. The word pranayama is comprised of two roots:
                      ‘Prana’ plus ‘Ayama’. Prana means vital energy or life
                      force.
                    </p>
                    <h3 className="mt-3">Study</h3>
                    <ul>
                      <li>Introduction of Pranayama</li>
                      <li>Benefits of pranayama</li>
                      <li>Explain the pranic body</li>
                      <li>Prana and lifestyle</li>
                      <li>Breath, health & Pranayama</li>
                      <li>Pranayama and the spiritual aspirants</li>
                    </ul>

                    <h3 className="mt-3">Practical & Theory</h3>
                    <ul>
                      <li>Nadishodhana Pranayama</li>
                      <li>Sheetali pranayama</li>
                      <li>Sheetkari Pranayama</li>
                      <li>Bramari Pranayama</li>
                      <li>Ujjayi Pranayama</li>
                      <li>Bhastrika Pranayama</li>
                      <li>Kapalbhati Pranayama</li>
                      <li>Surya Bheda pranayama</li>
                    </ul>

                    <h3 className="mt-3">Bandha (Energy Lock)</h3>
                    <p>
                      First definition of the bandha is that by restraining or
                      locking the muscle on a Physical level.
                    </p>
                    <ul>
                      <li>Preparation for Bandha</li>
                      <li>Benefits of Bandha</li>
                      <li>MulaBandha</li>
                      <li>JalandharaBandha</li>
                      <li>UddiyanaBandha</li>
                    </ul>
                  </>,
                ]}
              />
            </div>
            <div className="col-12 col-md-4">
              <Collapse
                id="hathayoga1"
                heading="Hatha Yoga"
                text={[
                  <>
                    <p>
                      Hatha yoga is considerd to be one of the best forms of
                      exercises all around the world and helps with weight loss,
                      improving health and overall condition of the body. It is
                      adviced to practice hatha yoga under the guidance of
                      expertes as it is considerd to be one of the difficult
                      part of learning yoga.
                    </p>
                    <h3 className="mt-3">Study</h3>
                    <ul>
                      <li>Joints Moments.</li>
                      <li>
                        Surya namaskara ( sun Salutation) &amp; Chandra
                        Namaskara (moon salutation)
                      </li>
                      <li>Yoga Mudrasana</li>
                      <li>Matsyasana</li>
                      <li>Gupta Padmasana</li>
                      <li>BaddhaPadmasana</li>
                      <li>Lolasana</li>
                      <li>Kukkutasana</li>
                      <li>Garbhasana</li>
                    </ul>
                  </>,
                ]}
              />
            </div>

            <div className="col-12 col-md-4">
              <Collapse
                id="astangaVinyasa1"
                heading="Ashtanga Vinyasa"
                text={[
                  <>
                    <b>Introduction of Ashtanga Vinyasa</b>
                    <ul>
                      <li>Components of Ashtanga Vinyasa</li>
                      <li>Importance of Breath In Ashtanga Vinyasa</li>
                      <li>Primary Series Of Ashtanga Vinyasa</li>
                    </ul>
                  </>,
                ]}
              />
            </div>

            <div className="col-12 col-md-4">
              <Collapse
                id="shatkarma"
                heading="Shatkarma"
                text={[
                  <>
                    <p>
                      Shatkarma is a Sanskrit word which is made by 2 different
                      words which is Shat plus Karma and also known as
                      Shaktkriya. This is a traditional Yogic cleansing
                      technique which is very effective and good to remove many
                      illnesses and open the body blockages.
                    </p>
                    <ul>
                      <li>Agni Saar</li>
                      <li>JalNeti</li>
                      <li>RuberNeti</li>
                      <li>Tirieng Mukha Eka Pada Paschimottanasana</li>
                      <li>Sutra Neti</li>
                      <li>Dand</li>
                    </ul>
                  </>,
                ]}
              />
            </div>

            <div className="col-12 col-md-4">
              <Collapse
                id="HumanAnatomy1"
                heading="Human Anatomy"
                text={[
                  <>
                    <p>
                      The basic structure and function of the organs and system
                      is very important for the practitioners so that their work
                      as a yoga teacher can be more enhanced. Knowing the
                      different types of joints and their range of motions helps
                      our students to be safe from injuries and also keep safe
                      their students after they start teaching as a teacher.
                    </p>
                    <ul>
                      <li>Types of bones</li>
                      <li>Circulatory system</li>
                      <li>Types of heart block</li>
                      <li>Skeletal system</li>
                    </ul>
                  </>,
                ]}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <TinyBanner />
        </div>

        <Accomodation inSide="yes" />
        <Food />

        <div className="row">
          <div className="col-12 mt-3">
            <Heading
              heading={<b>200 Hours Teacher Training Course In Rishikesh</b>}
            />
          </div>
          <div className="col-12 col-md-11 mx-auto w-100">
            <ResponsiveTable
              data={[
                [
                  "200 Hours Teacher Training Course",
                  "4th June To 29th June",
                  "$850/$1100",
                  "10/20",
                  "250$ Booking Amount",
                ],
                [
                  "200 Hours Teacher Training Course",
                  "4th July To 29th July",
                  "$850/$1100",
                  "10/20",
                  "250$ Booking Amount",
                ],
                [
                  "200 Hours Teacher Training Course",
                  "4th August 29th August",
                  "$850/$1100",
                  "10/20",
                  "250$ Booking Amount",
                ],
                [
                  "200 Hours Teacher Training Course",
                  "4th April To 29th April",
                  "$850/$1100",
                  "10/20",
                  "250$ Booking Amount",
                ],
              ]}
            />
          </div>
        </div>

        <DailySchedule img="../assets/images/pankaj/vertical/pankajyogpeeth (4).jpg" />
        <WhatDoWeOffer />
        <Expect
          img="../assets/images/pankaj/vertical/pankajyogpeeth (7).jpg"
          dir="rtl"
        />
        <QuestionAnswer />
      </div>
    </>
  );
}
