import { Routes, Route, BrowserRouter } from "react-router-dom";

import AboutUs from "../screens/common/AboutUs";
import IndexTopCard from "../screens/Index";
import { Teachers } from "../screens/teachers/Teachers";
import { ContactUs } from "../screens/common/ContactUs";

// Rishikesh Rishikesh Rishikesh
import { Rishikesh } from "../screens/rishikesh/Rishikesh";
import { RishikeshHundredHours } from "../screens/rishikesh/RishikeshHundredHours";
import { RishikeshTwoHundredHours } from "../screens/rishikesh/RishikeshTwoHundredHours";
import { RishikeshThreeHundred } from "../screens/rishikesh/RishikeshThreeHundred";
// import { RishikeshFiveHundred } from "../screens/rishikesh/RishikeshFiveHundred";
// Rishikesh Rishikesh Rishikesh

// Kearala Kearala Kearala Kearala
import { Kerala } from "../screens/kerala/Kerala";
// import { KeralaFiveHundred } from "../screens/kerala/KeralaFiveHundred";
import { KeralaThreeHundred } from "../screens/kerala/KeralaThreeHundred";
import { KeralaTwoHundred } from "../screens/kerala/KeralaTwoHundredHours";
import { KeralaHundredHours } from "../screens/kerala/KeralaHundredHours";
// Kearala Kearala Kearala Kearala

// Kearala Kearala Kearala Kearala
import { Nepal } from "../screens/nepal/Nepal";
// import { NepalFiveHundred } from "../screens/nepal/NepalFiveHundred";
import { NepalThreeHundred } from "../screens/nepal/NepalThreeHundred";

import { NepalTwoHundredHours } from "../screens/nepal/NepalTwoHundredHours";
import { NepalHundredHours } from "../screens/nepal/NepalHundredHours";
// Kearala Kearala Kearala Kearala

// Retreats
// import { Rishikesh as RishikeshRetreat } from "../screens/retreat/Rishikesh";
import { Kerala as KeralaRetreat } from "../screens/retreat/Kerala";
import { Nepal as NepalRetreat } from "../screens/retreat/Nepal";
import { ShriLanka } from "../screens/retreat/ShriLanka";
import { Rishikesh as RishikeshRetreat } from "../screens/retreat/Rishikesh";
// Retreats

import { Error } from "../screens/error/Error";
import { Sitemap } from "../components/util/Sitemap";

import { FullPost } from "../screens/blogs/FullPost";
import { Blogs } from "../screens/blogs/Blogs";
import Login from "../screens/Auth/Login";
import Signup from "../screens/Auth/Signup";
import Profile from "../screens/blogs/Profile";
import YinYoga from "../screens/Yin/YinYoga";

export function PageRoutes() {
  return (
    <Routes history={BrowserRouter}>
      {/* Authentication */}

      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Signup />} />

      {/* Authentication */}
      <Route exact path="/" element={<IndexTopCard />} />
      <Route exact path="/about-us" element={<AboutUs />} />
      <Route exact path="/teachers" element={<Teachers />} />
      <Route exact path="/contact-us" element={<ContactUs />} />
      {/* *=Rishikes=* *=Rishikes=* *=Rishikes=* */}
      <Route exact path="/rishikesh" element={<Rishikesh />} />
      <Route
        exact
        path="/rishikesh/100-hours-yoga-teacher-training-course-in-rishikesh"
        element={<RishikeshHundredHours />}
      />
      <Route
        exact
        path="/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh"
        element={<RishikeshTwoHundredHours />}
      />
      <Route
        exact
        path="/rishikesh/300-hours-yoga-teacher-training-course-in-rishikesh"
        element={<RishikeshThreeHundred />}
      />
      {/* *=Rishikes=* *=Rishikes=* *=Rishikes=* */}
      {/* *=Kerala=* *=Kerala=* *=Kerala=* */}
      <Route exact path="/kerala" element={<Kerala />} />
      <Route
        exact
        path="/kerala/100-hours-yoga-teacher-training-course-in-kerala"
        element={<KeralaHundredHours />}
      />
      <Route
        exact
        path="/kerala/200-hours-yoga-teacher-training-course-in-kerala"
        element={<KeralaTwoHundred />}
      />
      <Route
        exact
        path="/kerala/300-hours-yoga-teacher-training-course-in-kerala"
        element={<KeralaThreeHundred />}
      />
      {/* *=Kerala=* *=Kerala=* *=Kerala=* */}
      {/* *=Nepal=* *=Nepal=* *=Nepal=* */}
      <Route exact path="/nepal" element={<Nepal />} />
      <Route
        exact
        path="/nepal/100-hours-yoga-teacher-training-course-in-nepal"
        element={<NepalHundredHours />}
      />
      <Route
        exact
        path="/nepal/200-hours-yoga-teacher-training-course-in-nepal"
        element={<NepalTwoHundredHours />}
      />
      <Route
        exact
        path="/nepal/300-hours-yoga-teacher-training-course-in-nepal"
        element={<NepalThreeHundred />}
      />

      {/* Yin Yoga */}
      <Route exact path="/yin" element={<YinYoga />} />
      {/* Yin Yoga */}

      {/* Retreats */}
      <Route exact path="/nepal/retreat" element={<NepalRetreat />} />
      <Route exact path="/kerala/retreat" element={<KeralaRetreat />} />
      <Route exact path="/srilanka/retreat" element={<ShriLanka />} />
      <Route exact path="/rishikesh/retreat" element={<RishikeshRetreat />} />
      <Route exact path="*" element={<Error />} />
      <Route exact path="/sitemap" element={<Sitemap />} />
      {/* Authenticated Pages Authenticated Pages  */}
      {/* Authenticated Pages Authenticated Pages  */}
      <Route exact path="/blogs/:postid/:title" element={<FullPost />} />
      <Route exact path="/profile" element={<Profile />} />
      {/* Authenticated Pages Authenticated Pages*/}
    </Routes>
  );
}
