import { Banner } from "../../components/util/Banner";
import { RightImg } from "../../components/util/RightImg";
import { Heading } from "../../components/util/Heading";
import { CheckCard } from "../../components/util/CheckCard";
import { MetaForOthers } from "../meta/MetaForOthers";

import style from "../../css/Authentication.module.css";
import { FloatingButton } from "../../components/util/FloatingButton";

export function Rishikesh() {
  return (
    <>
      <MetaForOthers
        title="Retreat in Rishikesh | Learn Yoga And Enjoy A Week Together | www.pankajyogpeeth.com"
        region="NP-NPR"
        placename="Rishikesh"
        position="30.123618470767628, 78.32271291310317"
        description="Yoga Retreat In Rishikesh: Enjoy Your Week With Yoga And Healthy Food And Sight Seeing With Accomodation."
      />

      <Banner
        img="/assets/images/pankaj/rishikesh/pankajyogpeeth.jpg"
        city={<b>Rishikesh Retreat</b>}
        title="Yoga In Rishikesh"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <Heading heading="Yoga Retreat in Rishikesh" />
            <p>
              During the 10 days yoga retreat you will rejuvenate the energy
              inside you, the feel the calm, peaceful , mesmerizing with the
              nature and the yoga practice.
            </p>
            <p>
              The satvic meals will fulfil you with all the positive energy and
              help you to find healthy lifestyle.
            </p>
            <p>
              In this wonderful retreat we will also explore the surrounding and
              try to connect ourself with the beautiful nature around us, for
              feel the energy of the lord sun we will plan a sunrise trip on the
              magical mountains.
            </p>
            <p>
              Second we will give ourself space from all the chaos and try to
              escape from the city to the village for the silence vibes in the
              camping, where we will do some borne fire , singing bhajans ,
              connecting with each other , chanting mantras and a practice of
              asana.
            </p>
          </div>

          <div className="mt-5">
            <Heading heading="what do we Include In Retreat?" />
            <RightImg
              cols="col-12 col-md-5"
              cols1="col-12 col-md-7"
              img="../assets/images/pankaj/rishikesh/PankajYogpeeth (29).jpeg"
              title="Yoga Retreat In Rishikesh"
              data={
                <div className="row">
                  <CheckCard
                    cols="col-12 col-sm-6"
                    data={[
                      "Accomodation",
                      "Food",
                      "Traveling",
                      "hearbal Tea",
                      "Yoga Classes",
                      "Sight Seeing",
                      "Pranayam Class",
                      "Meditation Class",
                      "Single Room",
                      "Shared Room",
                    ]}
                  />{" "}
                </div>
              }
            />
          </div>

          <div className="mt-5">
            <Heading heading="Our Yoga Journey During This Course" />
            <RightImg
              dir="rtl"
              cols="col-12 col-md-5"
              cols1="col-12 col-md-7"
              img="../assets/images/pankaj/rishikesh/PankajYogpeeth (50).jpg"
              title="Yoga Retreat In Rishikesh"
              data={
                <div className="px-5">
                  <div>
                    During 10 Days course Pankaj Yogapeeth take their student on
                    two trips:-
                  </div>
                  <div>
                    <b>First:- </b>
                    Morning sunrise with whole group
                  </div>
                  <div>
                    <b>Second:- </b>
                    Camping with group
                  </div>
                  <div>
                    everyday classes 2 morning classes and two evening classes,
                    <div className="mt-2">
                      <h5>
                        <b>In morning:- </b>
                      </h5>
                      <h6>
                        <b> 7am to 8am - </b>
                        Hatha Yoga
                      </h6>
                      <h6>
                        <b> 8am to 9am - </b>
                        Pranayama
                      </h6>
                    </div>
                    <div className="mt-2">
                      <h5>
                        <b>In evening:- </b>
                      </h5>
                      <h6>
                        <b> 5pm to 6pm - </b>
                        Asana
                      </h6>
                      <h6>
                        <b> 6am to 7am - </b>
                        Meditation
                      </h6>
                    </div>
                    <div className="mt-2">
                      Pankaj Yogapeeth Provides Hygienic, Vegan and Gluten free
                      food twice a day, Breakfast and Dinner.
                    </div>
                  </div>
                </div>
              }
            />
          </div>

          <div className="mt-5">
            <Heading heading=" Most Important - Food" />
            <RightImg
              cols="col-12 col-md-5"
              cols1="col-12 col-md-7"
              img="../assets/images/pankaj/accomodation/pankajyogpeethfood.jpg"
              heading={
                <h3>
                  <b>
                    Most Important Thing is food That We Offers To Our Precious
                    Guests:-
                  </b>
                </h3>
              }
              title="Yoga Retreat In Rishikesh"
              data={
                <>
                  <div className="row">
                    <CheckCard
                      cols="col-12 col-md-6"
                      data={["Hygienic", "Healthy", "Protene", "Tasty"]}
                    />
                    <p className="mt-4">
                      Food is the most important element in yoga retreat, this
                      is directly related to our physical and mental strength.
                      we constantly expend energy in various yogic activity, so
                      we fill it with the delicious healthy organic food.These
                      things have an effect on the physical and mental
                      activities of the living being. physical health is also
                      important as much as the mental health. We offer delicious
                      vegan and vegetarian meals with lots of healthy food
                      available according to season. we offers fresh juices and
                      seasonal fruits salad.In retreat we also provide the
                      traditional meaLs according to the locations Or the places
                      we are conducting a yoga retreat. we also respect your
                      needs of particular food and will help in arrange with all
                      support.
                      <p>We provides two meals per day Breakfast and dinner.</p>
                    </p>
                  </div>
                </>
              }
            />
          </div>

          <div className="mt-5">
            <Heading heading="Accommodation" />
            <RightImg
              dir="rtl"
              cols="col-12 col-md-5"
              cols1="col-12 col-md-7"
              img="../assets/images/pankaj/accomodation/pankajyogpeethsharing1.jpg"
              title="Yoga Retreat In Rishikesh"
              data={
                <>
                  <div className="row">
                    <CheckCard
                      cols="col-12 col-md-6"
                      data={["Clean", "Hygienic", "Single/Shared", "Ac/Non-Ac"]}
                    />
                    <p className="mt-4">
                      Rishikesh offers spectacular views of the jungle-covered
                      hills. Rishikesh also has the confluence of the Ganges and
                      Chandrabagha rivers. The great attraction of Rishikesh is
                      the river Ganges itself, which rushes through the city.
                      From ancient times to today, many yogis, rishis, sages and
                      sannyasis have taken an interest in Rishikesh to practice
                      yoga in this peaceful place. Since then, Rishikesh has
                      been called the abode of the sages. Rishikesh is perhaps
                      the world's most famous holy city for yoga with its
                      numerous ashrams. Rishikesh is now world renowned as the
                      yoga capital of the planet. Once a year he hosts a
                      week-long International Yoga Festival in Rishikesh in
                      March.
                      <p>
                        Pankaj Yogpeeth provides Hygienic and Clean room to
                        their yogic where they can relax and sleep during night
                        time.
                      </p>
                    </p>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>

      <div className={style.bookRetreat}></div>
      <FloatingButton
        title3="Single"
        price3="1000 $"
        title2="Shared"
        price2="850 $"
        book="Book"
        now="Now"
        url3="https://www.paypal.me/pankajsadana57"
        url2="https://www.paypal.me/pankajsadana57"
      />
    </>
  );
}
