import Styles from "../../css/Custom.module.css";
import { NavLink } from "react-router-dom";
import { ImCross } from "react-icons/im";
export function SlidingMenu(props) {
  return (
    <div
      className={`${Styles.SlidingMenu} ${
        props.displayFullPageMenu ? props.displayFullPageMenu : "d-none"
      }`}
    >
      <div className={Styles.cancelBtn} onClick={props.cancelBtn}>
        <ImCross />
      </div>
      <div>
        <img
          src="../assets/images/pankaj/logo/pankajyogapeeth.png"
          alt="Pankaj Yogpeeth"
          title="Best Yoga School In Rishikesh"
        />

        <div className="d-flex flex-column text-center">
          <h3 className="my-2">
            <NavLink onClick={props.hideMenu} to="/">
              Home
            </NavLink>
          </h3>
          <h3
            className="my-2"
            data-bs-toggle="collapse"
            data-bs-target="#course_dropdown"
          >
            <span> Courses </span>
          </h3>

          <div id="course_dropdown" className="collapse">
            <div className="d-flex flex-column">
              <NavLink
                onClick={props.hideMenu}
                className="mt-2"
                to="/rishikesh"
              >
                Rishikesh
              </NavLink>
              <NavLink onClick={props.hideMenu} className="mt-2" to="/kerala">
                Kerala
              </NavLink>
              <NavLink onClick={props.hideMenu} className="mt-2" to="/nepal">
                Nepal
              </NavLink>
              <NavLink onClick={props.hideMenu} className="mt-2" to="/yin">
                Yin Yoga
              </NavLink>
            </div>
          </div>
          <h3
            className="my-2"
            data-bs-toggle="collapse"
            data-bs-target="#retreat_dropdown"
          >
            <span> Retreat </span>
          </h3>
          <div id="retreat_dropdown" className="collapse">
            <div className="d-flex flex-column">
              <NavLink
                onClick={props.hideMenu}
                className="mt-2"
                to="/rishikesh/retreat"
              >
                Rishikesh
              </NavLink>

              <NavLink
                onClick={props.hideMenu}
                className="mt-2"
                to="/kerala/retreat"
              >
                Kerala
              </NavLink>

              <NavLink
                onClick={props.hideMenu}
                className="mt-2"
                to="/nepal/retreat"
              >
                Nepal
              </NavLink>
              <NavLink
                onClick={props.hideMenu}
                className="mt-2"
                to="/srilanka/retreat"
              >
                Sri Lanka
              </NavLink>
            </div>
          </div>

          <h3 className="mt-2">
            <NavLink onClick={props.hideMenu} to="/teachers">
              {" "}
              Teachers{" "}
            </NavLink>
          </h3>

          <h3 className="mt-2">
            <NavLink onClick={props.hideMenu} to="/about-us">
              {" "}
              About Us{" "}
            </NavLink>
          </h3>

          <h3 className="mt-2">
            <NavLink onClick={props.hideMenu} to="/contact-us">
              {" "}
              Contact Us{" "}
            </NavLink>
          </h3>
        </div>
      </div>
    </div>
  );
}
