import React, { useState } from "react";
import { Heading } from "./Heading";
import Style from "../../css/Custom.module.css";

function WhatDoWeOffer() {
  const [disImg, setDisImg] = useState("Pranayama");
  const [oldId, setOldId] = useState("Pranayama");
  // const [imgId, setImgId] = useState("Pranayama");

  function listItem(event) {
    const clickId = event.target.id;

    if (event.target.tagName === "H3") {
      document.getElementById(oldId).classList.remove(Style.cusButton);
      document.getElementById(clickId).classList.add(Style.cusButton);
      setDisImg(clickId);
      setOldId(clickId);
    }
  }

  return (
    <div className="row">
      <div className="col-12 text-center">
        <Heading heading="What Do We Offer?" />
      </div>

      {/* tabs */}
      <div className="col-12 m-auto ">
        <ul
          className={Style.Custom_Slider}
          onClick={(event) => listItem(event)}
          id="keyWords"
        >
          <li className="my-2">
            <h3 id="Pranayama" className={Style.cusButton}>
              Pranayama
            </h3>
          </li>
          <li className="my-2">
            <h3 id="Meditation">Meditation</h3>
          </li>
          <li className="my-2">
            <h3 id="Yoga Philosophy">Yoga Philosophy</h3>
          </li>
          <li className="my-2">
            <h3 id="Mantra Yoga">Mantra Yoga</h3>
          </li>
          <li className="my-2">
            <h3 id="Yogic Anatomy">Yogic Anatomy</h3>
          </li>
        </ul>
      </div>

      {/* User Images */}
      <div className="col-12 px-4 col-lg-8 m-auto">
        <div className="row">
          <div className="col-12">
            <div id="offerImgContainer">
              <img
                src="../assets/images/pankaj/rishikesh/PankajYogpeeth (68).jpg"
                alt="rishikesh"
                className="img-fluid"
                style={{ display: "Pranayama" === disImg ? "block" : "none" }}
              />
              <img
                src="../assets/images/pankaj/teachers/t13.jpg"
                alt="rishikesh"
                className="img-fluid"
                style={{ display: "Meditation" === disImg ? "block" : "none" }}
              />
              <img
                src="../assets/images/pankaj/PankajYogpeeth (55).jpg"
                alt="rishikesh"
                className="img-fluid"
                style={{
                  display: "Yoga Philosophy" === disImg ? "block" : "none",
                }}
              />
              <img
                src="../assets/images/pankaj/Nepal/PankajYogpeeth (73).jpg"
                alt="rishikesh"
                className="img-fluid"
                style={{ display: "Mantra Yoga" === disImg ? "block" : "none" }}
              />
              <img
                src="../assets/images/pankaj/PankajYogpeeth (75).jpg"
                alt="rishikesh"
                className="img-fluid"
                style={{
                  display: "Yogic Anatomy" === disImg ? "block" : "none",
                }}
              />
            </div>
          </div>
          <div className="col-12 m-auto mt-3">
            <div style={{ display: "Pranayama" === disImg ? "block" : "none" }}>
              <p>
                Pranayama is the yogic practice of focusing on breath. In
                Sanskrit, prana means "vital life force", and yama means to gain
                control. In yoga, breath is associated with the prana, thus,
                pranayama is a means to elevate the prana shakti, or life
                energies.
              </p>
            </div>

            {/* meditation */}
            <div
              style={{ display: "Meditation" === disImg ? "block" : "none" }}
            >
              <ul>
                <li>Mindfulness Meditation.</li>
                <li>Spiritual Meditation.</li>
                <li>Focused Meditation.</li>
                <li>Movement Meditation.</li>
                <li>Mantra Meditation.</li>
                <li>Transcendental Meditation.</li>
                <li>Progressive Relaxation.</li>
                <li>Loving-kindness Meditation</li>
              </ul>
              <p>
                Pranayama is the yogic practice of focusing on breath. In
                Sanskrit, prana means "vital life force", and yama means to gain
                control. In yoga, breath is associated with the prana, thus,
                pranayama is a means to elevate the prana shakti, or life
                energies.
              </p>
            </div>
            {/* meditation */}

            {/* Yoga Philosophy */}
            <div
              style={{
                display: "Yoga Philosophy" === disImg ? "block" : "none",
              }}
            >
              <p>
                Yoga Philosophy includes theory related to yoga this part of the
                200 Hour Yoga TTC at Rishikesh Yoga Teacher Training Center
                covers following topics :
              </p>
              <ul>
                <li>Introduction of Yoga</li>
                <li>Yoga Pantanjali sutra</li>
                <li>5 basic Element</li>
                <li>Integrating yoga in daily practice</li>
                <li>Principle of Yoga</li>
                <li>8 limbs of Yoga</li>
                <li>Three Dosha</li>
              </ul>
            </div>
            {/* Yoga Philosophy */}

            {/* Mantra Yoga */}
            <div
              style={{
                display: "Mantra Yoga" === disImg ? "block" : "none",
              }}
            >
              <p>
                Mantra chanting is an effective way to align your vibrations and
                awareness with the aspects of the self and infinite. It is a
                great for improving concentration, attention, focus and acts an
                effective method to release emotions:
              </p>
              <ul>
                <li>Om chanting</li>
                <li>MahaMrityunjaya Mantra</li>
                <li>Gayatri Mantra</li>
                <li>Guru Vandana (prayer to Guru)</li>
                <li>Prayer before class &amp; Meal.</li>
              </ul>
            </div>
            {/* Mantra Yoga */}

            {/* Mantra Yoga */}
            <div
              style={{
                display: "Yogic Anatomy" === disImg ? "block" : "none",
              }}
            >
              <p>
                The basic structure and function of the organs and system is
                very important for the practitioners so that their work as a
                yoga teacher can be more enhanced. Knowing the different types
                of joints and their range of motions helps our students to be
                safe from injuries and also keep safe their students after they
                start teaching as a teacher.
              </p>
              <ul>
                <li>Types of bones</li>
                <li>Circulatory system</li>
                <li>Types of heart block</li>
                <li>Skeletal system</li>
              </ul>
            </div>
            {/* Mantra Yoga */}
          </div>
        </div>
      </div>

      {/* text */}
    </div>
  );
}

export default WhatDoWeOffer;
