import { Heading } from "./Heading";
import { RightImg } from "./RightImg";

export function Expect({ img, dir }) {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Heading heading={<b> What Do We Expect From Students </b>} />

        <RightImg
          img={img || "../assets/images/index/namaste.jpg"}
          data={
            <>
              <ul>
                <li className="my-3">
                  Students should maintain proper discipline and decorum in the
                  school environment..
                </li>
                <li className="my-3">
                  No alcohol, meat or smoking allowed on the campus.
                </li>
                <li className="my-3">
                  Students should abstain from the intake of intoxicants during
                  the course.
                </li>
                <li className="my-3">
                  An attendance of 85% Is mandatory for the successful
                  completion of the yoga alliance certificate.
                </li>
                <li className="my-3">
                  Any misbehavior during the course in the campus or in classes
                  will lead to a point of cancellation of your certificate even
                  forceful abandonment from the campus.
                </li>
                <li className="my-3">
                  In case of any emergency, if a student needs to leave the
                  course halfway, his/her money will not be reimbursed, however,
                  he/she can attend the same course, at the same location at a
                  different time with the permission of the organizers.
                </li>
                <li className="my-3">
                  A non-refundable fee of $250 will be charged to book a seat
                  for one time before the course.
                </li>
                <li className="my-3">
                  The school does not provide health insurance any damage
                  covering during the course.
                </li>
                <li className="my-3">
                  A student will be responsible for his or her own wellbeing and
                  must communicate his/her special needs and follow teachers
                  advice to avoid injuries.
                </li>
                <li className="my-3">
                  The time table will be provided during the orientation and
                  must be adhered to by all and sundry.
                </li>
              </ul>
            </>
          }
          dir={dir}
        />
      </div>
    </div>
  );
}
