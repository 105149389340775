import { useEffect, useState } from "react";
import axios from "axios";
import style from "../../css/Authentication.module.css";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = new useNavigate();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    localStorage.getItem("userId") ? navigate("/blogs") : setLoading(false);
  }, [navigate]);

  const formSubmit = () => {
    axios
      .post("login", formData)
      .then(
        (res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.id);
          res.data.status === 200 && navigate("/blogs");
        }

        // navigate("/blogs")
      )
      .catch((err) => console.log(err.response.data.errors));
  };

  return loading ? (
    "...loading"
  ) : (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 mt-5">
          <div className={`card ${style.registrationCard} border-0`}>
            <div className={`${style.logoWrapper} d-flex flex-wrap`}>
              <img
                src="assets/images/pankaj/logo/pankajyogapeeth.png"
                className={`img-fluid ${style.logo}`}
                alt=""
              />
              <h1> Pankaj Yogpeeth </h1>
              <h3> Best Yoga School </h3>
            </div>
            <div className={`${style.formWrapper}`}>
              <div className="form-group w-100 mb-3">
                <label htmlFor="email" className="text-white">
                  Email
                </label>
                <input
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="email@gmail.com"
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
                {errors.email && (
                  <span className="small text-danger"> {errors.email} </span>
                )}
              </div>
              <div className="form-group w-100 mb-3">
                <label htmlFor="password" className="text-white">
                  Password
                </label>
                <input
                  autoComplete="off"
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="123456789"
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                  security="true"
                />

                <div className="d-flex justify-content-between flex-wrap">
                  {errors.password && (
                    <span className="small text-danger">{errors.password}</span>
                  )}

                  <span
                    className={`small text-danger ${style.pointer}`}
                    onClick={() => alert("hey")}
                  >
                    {" "}
                    Forget Password{" "}
                  </span>
                </div>
              </div>

              <div className="d-flex flex-column">
                <div className="mx-auto">
                  <button
                    className={`btn ${style.cusButton}`}
                    onClick={formSubmit}
                  >
                    Submit
                  </button>
                </div>

                <span className="text-white mt-2">
                  I don't have account,
                  <b
                    className={`text-dark mx-1 ${style.pointer}`}
                    onClick={() => navigate("/register")}
                  >
                    Signup
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
