import { useEffect } from "react";
import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { Courses } from "../../components/util/Courses";

import { Steppers } from "../../components/util/Steppers";

import { QuestionAnswer } from "../../components/util/QuestionAnswer";

import { MetaKerala } from "../meta/MetaKerala";
import WhatDoWeOffer from "../../components/util/WhatDoWeOffer";
import { Expect } from "../../components/util/Expect";
import { DailySchedule } from "../../components/util/DailySchedule";

export function Kerala() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  <MetaKerala
    title="Best Yoga School In Kerala | Best Yoga Academy In Kerala"
    description="Best Yoga School In Kerala. You Can Learn Yoga To  Our Team Of Experts They Are Professionally Practicing Yoga More Than 10 Years. You Will Get Life Time Support"
    placename="kerala"
    ogUrl="https://www.pankajyogpeeth.com/kerala"
    imgUrl="https://pankajyogpeeth.com/assets/images/new/banners/nepal-100.jpg"
  />;

  return (
    <>
      <div className="container-fluid">
        {/* steppers */}
        <Steppers navlinks={[["/kerala", "Kerala"]]} />
        <div className="row">
          {/* Banner top */}
          <div className="col-12 px-0">
            <Banner
              img="../assets/images/pankaj/kerala/PankajYogpeeth (54).jpg"
              city="Yoga In Kerala"
              title="Yoga In Kerala"
            />
          </div>

          <div className="col-12 col-md-10 mx-auto px-4">
            <Heading heading={<b>Yoga In The Holy City Of Kerala</b>} />
            <p className="text-center">
              Yoga teacher training in Kerala, includes both theoretical and
              practical aspects of Yoga. It encourages and motivates students to
              do a self enquiry and start looking for answers within. It is an
              intense teaching training course, where you will be shaped as a
              prominent yoga teacher and also given responsibilty to share this
              sacred knowledge to the next generation. And mean while
              understanding more about our own existance.
            </p>
          </div>

          <div className="col-12 px-3">
            <Heading
              heading={<b>Upcoming Yoga Teacher Training in Kerala</b>}
            />
          </div>

          <Courses
            bookNowBtn="Book Now"
            img="./assets/images/pankaj/kerala/PankajYogpeeth (16).jpg"
            title="Yoga In Kerala"
            heading="100 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "100 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 650$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 900$ for Single Accomodation,
              </span>,
            ]}
            link="/kerala/100-hours-yoga-teacher-training-course-in-kerala"
          />

          <Courses
            bookNowBtn="Book Now"
            img="./assets/images/pankaj/kerala/PankajYogpeeth (17).jpg"
            title="Yoga In Kerala"
            heading="200 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "200 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 950$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1250$ for Single Accomodation,
              </span>,
            ]}
            link="/kerala/200-hours-yoga-teacher-training-course-in-kerala"
            dir="rtl"
          />

          <Courses
            bookNowBtn="Book Now"
            img="./assets/images/pankaj/kerala/PankajYogpeeth new.jpeg"
            title="Yoga In Kerala"
            heading="300 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "300 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 1100$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1400$ for Single Accomodation,
              </span>,
            ]}
            link="/kerala/300-hours-yoga-teacher-training-course-in-kerala"
          />

          <Courses
            bookNowBtn="Book Now"
            img="./assets/images/chakra/chakra.webp"
            title="Yoga In Kerala"
            heading="500 hours yoga teacher training course"
            text={[
              "500 Hours Teacher Training Course",
              <h3 className="text-primary"> Comming Soon </h3>,
            ]}
            link="/kerala/500-hours-yoga-teacher-training-course-in-kerala"
          />
        </div>

        <DailySchedule img="../assets/images/pankaj/vertical/pankajyogpeeth (1).jpg" />
        <WhatDoWeOffer />
        <Expect
          img="../assets/images/pankaj/vertical/pankajyogpeeth (2).jpg"
          dir="rtl"
        />
        <QuestionAnswer />
      </div>
    </>
  );
}
