import Styles from "../../css/Custom.module.css";

import { IoFastFoodOutline } from "react-icons/io5";
import { IoBedOutline } from "react-icons/io5";
import { RiCupLine } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";
export function TinyBanner(props) {
    return (

        // <div className="col-12 px-0 mx-auto">
        //     <div className={Styles.striped}>
        //         {
        //             props.data ? props.data.map((item, index) =>
        //                 <div key={index + item[2]}>

        //                     <div className={`${Styles.textTooltip} mx-auto`}>
        //                         {item[0]}
        //                     </div>
        //                     {/* <IoFastFoodOutline className={Styles.icon} /> */}
        //                     {item[1]}
        //                     <h4> {item[2]} </h4>
        //                 </div>

        //             )
        //                 : ""
        //         }
        //     </div>
        // </div>
        <div className="col-12 px-0 mx-auto">
            <div className={Styles.striped}>


                <div>
                    <div className={`${Styles.textTooltip} mx-auto`}>
                        Pankaj Yogpeeth Porvides Healthy And Hygenic Food  To Their Students.
                    </div>
                    <IoFastFoodOutline size="calc(90px + 5vw)" />
                    <h4> Food </h4>
                </div>
                <div>
                    <div className={`${Styles.textTooltip} mx-auto`}>
                        Comfortable bed with clean rooms provided by Pankaj Yogpeeth.
                    </div>
                    <IoBedOutline size="calc(90px + 5vw)" />
                    <h4> Accomodation </h4>
                </div>
                <div>
                    <div className={`${Styles.textTooltip} mx-auto`}>
                        We offer herbal and ayurvedic tea in morning and evening.
                    </div>
                    <RiCupLine size="calc(90px + 5vw)" />
                    <h4> Tea </h4>
                </div>
                <div>
                    <div className={`${Styles.textTooltip} mx-auto`}>
                        Pankaj yogpeeth have experienced teachers and who's sharing their knowledge more than 10 years.
                    </div>
                    <FaChalkboardTeacher size="calc(70px + 2vw)" />
                    <h4> Teachers </h4>
                </div>
            </div>
        </div>

    )
}
