import { Steppers } from "../../components/util/Steppers";

import { useParams } from "react-router-dom";

export function FullPost() {
  const param = useParams();
  console.log("hey", param.title);
  return (
    <>
      <div className="container-fluid">
        <Steppers
          navlinks={[
            ["/blogs", "Blogs", "best yoga school in nepal"],
            [
              `/blogs/${param.postid}/${param.title}`,
              "Blogs world",
              `${param.title.replace(/_/g, " ")}`,
            ],
          ]}
        />
        <div className="row">
          {/* <div className="col-12 col-md-12 mx-auto px-0">
            <Banner
              img="../../../assets/images/new/banners/kerala.jpg"
              city="Blogs"
              title="BLogs"
            />
          </div> */}
          <div className="col-12 col-md-10 mx-auto"></div>
        </div>
      </div>
    </>
  );
}
