import { NavLink } from "react-router-dom";
import Styles from "../../css/Custom.module.css";
import { BsArrowRight } from "react-icons/bs";

export function Courses(props) {
  return (
    <div className="container-fluid" dir={props.dir ? "rtl" : ""}>
      <div className="row my-3 px-md-1 px-lg-2">
        <div className="col-12 px-4">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-4 my-auto">
                  <div className="position-relative">
                    <img
                      src={props.img}
                      className="img-fluid"
                      alt="Best Yoga school"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-8 my-auto text-center"
                  dir={props.dir ? "ltr" : ""}
                >
                  <h3>{props.heading}</h3>
                  {props.text.map((item, index) => (
                    <p className={Styles.index0} key={index}>
                      {" "}
                      {item}{" "}
                    </p>
                  ))}

                  <div className="d-flex flex-wrap justify-content-evenly">
                    {props.btnText ? (
                      <NavLink
                        to={props.link ? props.link : "/"}
                        className={`btn btn-lg ${Styles.button}`}
                      >
                        {props.btnText}
                        <div className={Styles.icon}>
                          <BsArrowRight color="blue" />
                        </div>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {props.bookNowBtn ? (
                      <a
                        href="https://www.paypal.me/pankajsadana57"
                        className={`btn btn-lg ${Styles.button}`}
                      >
                        <div className={Styles.tooltipCard}>
                          Pay 250$ &amp;
                          <br />
                          and book your seat now
                        </div>
                        Book Now
                        <div className={Styles.icon}>
                          <BsArrowRight color="blue" />
                        </div>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
