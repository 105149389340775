import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Styles from "../../css/Custom.module.css";
import TypingEffect from "../util/TypingEffect";

function IndexTopCard(props) {
  const [urlNum, setUrlNum] = useState(0);

  const urlName = [
    "100 Yours Yoga TTC",
    "200 Hours Yoga TTC",
    "300 Hours Yoga TTC",
  ];

  const chakraImg = [
    "./assets/images/pankaj/chakra1/1.png",
    "./assets/images/pankaj/chakra1/2.png",
    "./assets/images/pankaj/chakra1/3.png",
    "./assets/images/pankaj/chakra1/4.png",
    "./assets/images/pankaj/chakra1/5.png",
    "./assets/images/pankaj/chakra1/6.png",
    "./assets/images/pankaj/chakra1/7.png",
  ];

  const cutOut = [
    "./assets/images/pankaj/cutout/pankaj.png",
    "./assets/images/pankaj/cutout/pankaj2.png",
    "./assets/images/pankaj/cutout/swami_ji.png",
    "./assets/images/pankaj/cutout/pankaj.png",
    "./assets/images/pankaj/cutout/pankaj2.png",
    "./assets/images/pankaj/cutout/swami_ji.png",
    "./assets/images/pankaj/cutout/pankaj.png",
  ];

  const normal = {
    width: "auto",
  };

  const ChakrasName = [
    "Crown Chakra",
    "Third Eye Chakra",
    "Throat Chakra",
    "Heart Chakra",
    "The Solar Plexus Chakra",
    "The Sacral Chakra",
    "The Base/Root Chakra",
  ];

  return (
    <div className={`${Styles.indexTopCard}`} dir="rtl">
      <div className={`${Styles.imgContainer} mt-0 mt-md-5`} dir="ltr">
        <img
          src={`${chakraImg[props.number]}`}
          className={Styles.chakraImg}
          alt={ChakrasName[props.number]}
          title={ChakrasName[props.number]}
        />
        <img src={cutOut[props.number]} alt="Best Yoga School In Rishikesh" />
      </div>

      <div className={Styles.animatedTextContainer} dir="ltr">
        <TypingEffect
          AnimatedText="AnimatedText"
          dataText={[
            "100 Hours Yoga TTC",
            "200 Hours Yoga TTC",
            "300 Hours Yoga TTC",
          ]}
          buttonName={(a) => setUrlNum(a)}
        />

        <div className={Styles.buttonContainer}>
          <NavLink to="/">{urlName[urlNum]}</NavLink>
        </div>

        <div className={Styles.buttonWrapper}>
          <div className="my-auto">
            <NavLink
              to="/rishikesh"
              title="Best Yoga School In Rishikesh"
              className={Styles.button}
            >
              Rishikesh
            </NavLink>
          </div>
          <div className="my-auto">
            <NavLink
              to="/kerala"
              title="Best Yoga School In Kerala"
              className={Styles.button}
            >
              Kerala
            </NavLink>
          </div>
          <div className="my-auto">
            <NavLink
              to="/nepal"
              title="Best Yoga School In Nepal"
              className={Styles.button}
            >
              Nepal
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexTopCard;
