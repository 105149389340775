import React, { useState } from "react";
import Styles from "../../css/Custom.module.css";
import { NavLink } from "react-router-dom";
import { Callme } from "../util/Callme";
import { Email } from "../util/Email";
import { SlidingMenu } from "../util/SlidingMenu";

function Manubar() {
  const [displayFullPageMenu, setDisplayFullPageMenu] = useState("d-none");

  return (
    <>
      <div className={Styles.menuBar}>
        <div className={`${Styles.contactContainer}`}>
          <Callme numb1="+919997777457" numb2="+91 9997402794" />
          <div className="d-none d-sm-flex">
            <Email email1="pankajyogpeeth@gmail.com" />
          </div>
        </div>

        <div className={Styles.menuContainer}>
          <div className={Styles.logo}>
            <NavLink to="/">
              <img
                src="../assets/images/pankaj/logo/pankajyogapeeth.png"
                alt="Pankaj Yogpeeth"
                title="Best Yoga School In Rishikesh"
              />
            </NavLink>
          </div>

          <div className={`${Styles.menuItemsContainer} d-none d-md-flex`}>
            <NavLink to="/" className={Styles.menuItem}>
              Home
            </NavLink>

            <span
              className={`${Styles.menuItem} 
                        ${Styles.dropdownTab}`}
            >
              Courses
              <div className={Styles.dropdownMenu}>
                <NavLink to="/rishikesh" className={Styles.menuItem}>
                  Rishikesh
                </NavLink>

                <NavLink to="/kerala" className={Styles.menuItem}>
                  Kerala
                </NavLink>
                <NavLink to="/nepal" className={Styles.menuItem}>
                  Nepal
                </NavLink>
                <NavLink to="/yin" className={Styles.menuItem}>
                  Yin Yoga
                </NavLink>
              </div>
            </span>

            <span className={`${Styles.menuItem} ${Styles.dropdownTab}`}>
              Retreat
              <div className={Styles.dropdownMenu}>
                <NavLink to="/rishikesh/retreat" className={Styles.menuItem}>
                  Rishikesh
                </NavLink>
                <NavLink to="/kerala/retreat" className={Styles.menuItem}>
                  Kerala
                </NavLink>
                <NavLink to="/nepal/retreat" className={Styles.menuItem}>
                  Nepal
                </NavLink>
                <NavLink to="/srilanka/retreat" className={Styles.menuItem}>
                  Sri Lanka
                </NavLink>
              </div>
            </span>
            <NavLink to="/teachers" className={Styles.menuItem}>
              Teachers
            </NavLink>
            <NavLink to="/about-us" className={Styles.menuItem}>
              About Us
            </NavLink>
            <NavLink to="/contact-us" className={Styles.menuItem}>
              Contact Us
            </NavLink>
          </div>

          <div
            className={`d-flex d-md-none my-auto`}
            onClick={() => {
              setDisplayFullPageMenu("flex");
            }}
          >
            <div className={`${Styles.toggleBtn}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <SlidingMenu
        displayFullPageMenu={displayFullPageMenu}
        hideMenu={() => {
          setDisplayFullPageMenu("d-none");
        }}
        cancelBtn={() => {
          setDisplayFullPageMenu("d-none");
        }}
      />
    </>
  );
}

export default Manubar;
