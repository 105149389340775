import { BiDotsVerticalRounded } from "react-icons/bi";
import style from "../../css/PostCard.module.css";

export const showEditDelete = (userId) => {
  console.log(userId.userId);
};

export function PostEditDelete(userId) {
  return (
    <div className={style.editBox}>
      <BiDotsVerticalRounded
        onClick={() => {
          showEditDelete();
        }}
      />
      <div className={style.dropdown}>
        <span> Edit </span>
        <span> Delete </span>
      </div>
    </div>
  );
}
