import { useEffect } from "react";

import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { Courses } from "../../components/util/Courses";

import { InfoCard } from "../../components/util/InfoCard";
import { Steppers } from "../../components/util/Steppers";

import { MetaRishikesh } from "../meta/MetaRishikesh";
import Styles from "../../css/Custom.module.css";
import { QuestionAnswer } from "../../components/util/QuestionAnswer";
import WhatDoWeOffer from "../../components/util/WhatDoWeOffer";
import { DailySchedule } from "../../components/util/DailySchedule";
import { Expect } from "../../components/util/Expect";

export function Rishikesh() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaRishikesh
        ogUrl="https://www.pankajyogpeeth.com/rishikesh"
        title="Best Yoga School In Rishikesh | Yoga Academy In Rishikesh"
        description="Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Rishikesh. 100 hourse teacher traning course for beginners"
        position="28.210476194394715, 83.95781467854027"
        placename="Rishikesh"
        imageUrl="https://www.pankajyogpeeth.com/assets/images/new/banners/rishikesh.jpg"
      />

      <div className="container-fluid">
        {/* steppers */}
        <Steppers navlinks={[["/rishikesh", "Rishikesh"]]} />
        <div className="row">
          {/* Banner top */}
          <div className="col-12 px-0">
            <Banner
              img="./assets/images/pankaj/rishikesh/PankajYogpeeth (50).jpg"
              city="Yoga In Rishikesh"
              title="Yoga In Rishikesh"
            />
          </div>

          <div className="col-12 col-md-10 mx-auto px-4">
            <Heading heading={<b>Yoga In The Holy City Of Risshikesh</b>} />
            <p className="text-center">
              Yoga teacher training in Rishikesh, includes both theoretical and
              practical aspects of Yoga. It encourages and motivates students to
              do a self enquiry and start looking for answers within. It is an
              intense teaching training course, where you will be shaped as a
              prominent yoga teacher and also given responsibilty to share this
              sacred knowledge to the next generation. And mean while
              understanding more about our own existance.
            </p>
          </div>

          <div className="col-12 px-3">
            <Heading
              heading={<b>Upcoming Yoga Teacher Training in Risshikesh</b>}
            />
          </div>

          <Courses
            img="./assets/images/pankaj/rishikesh/PankajYogpeeth (3).jpeg"
            title="Yoga In Rishikesh"
            heading="100 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "100 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 650$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 900$ for Single Accomodation,
              </span>,
            ]}
            link="/rishikesh/100-hours-yoga-teacher-training-course-in-rishikesh"
            bookNowBtn="Book Now"
          />

          <Courses
            img="./assets/images/pankaj/rishikesh/PankajYogpeeth (13).jpeg"
            title="Yoga In Rishikesh"
            heading="200 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "200 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 850$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1100$ for Single Accomodation,
              </span>,
            ]}
            link="/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh"
            dir="rtl"
            bookNowBtn="Book Now"
          />

          <Courses
            img="./assets/images/pankaj/rishikesh/PankajYogpeeth (3).jpeg"
            title="Yoga In Rishikesh"
            heading="300 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "300 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 1000$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1250$ for Single Accomodation,
              </span>,
            ]}
            link="/rishikesh/300-hours-yoga-teacher-training-course-in-rishikesh"
            bookNowBtn="Book Now"
          />

          <Courses
            img="./assets/images/chakra/chakra.webp"
            title="Yoga In Rishikesh"
            heading="500 hours yoga teacher training course"
            text={[
              "500 Hours Teacher Training Course",
              <h3 className="text-primary"> Comming Soon </h3>,
            ]}
            link="/rishikesh/500-hours-yoga-teacher-training-course-in-rishikesh"
            dir="left"
          />
        </div>

        {/* schedule */}

        <div className="row">
          <div className="col-12 text-center mt-5">
            <Heading heading="Facts About Rishikesh" />
          </div>
        </div>

        <div className={Styles.wrapper}>
          <InfoCard
            img="./assets/images/pankaj/quotes/Rishikesh-world-capital-of-yoga.jpg"
            title="Yoga Capital Of world"
            textTitle="Best Yoga School In Rishikesh"
            text="Known as the Tapobhumi, a place for meditation and yoga for old age and gods. This place has been a pioneer destination for people to visit and learn yoga. A perfect place for the spiritual awakening in the lap of nature, the yoga heritage of Rishikesh is pristine."
          />

          <InfoCard
            img="./assets/images/pankaj/quotes/Char-Dham-ili.jpg"
            title="Gateway To Char Dham"
            textTitle="Best Yoga School In Rishikesh"
            text="Seek eternal spirituality and peace by exploring the Char Dham, which are Kedarnath, Badrinath, Gangotri, and Yamunotri. A place believed to offer immemorial meditation that liberates one from a cycle of life and death. Another fact about Rishikesh is, this is the door to Char Dham Yatra."
          />

          <InfoCard
            img="./assets/images/pankaj/quotes/Rishikesh.jpg"
            title="Dive In Adventure"
            textTitle="Best Yoga School In Rishikesh"
            text="A holy city is one that is beautifully decorated by nature. With innumerable adventure sports like rafting, camping, trekking, paragliding, and bungee jumping, Rishikesh is the perfect destination for adventure enthusiasts. "
          />
        </div>

        {/* <div className="d-flex justify-content-end px-5">
          <ViewMoreTab link="/teachers" name="View All" />
        </div> */}

        {/* <WhatDoWeOffer /> */}

        <DailySchedule img="../assets/images/pankaj/vertical/pankajyogpeeth (3).jpg" />
        <WhatDoWeOffer />
        <Expect
          img="../assets/images/pankaj/vertical/pankajyogpeeth (6).jpg"
          dir="rtl"
        />
        <QuestionAnswer />
      </div>
    </>
  );
}
