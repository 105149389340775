import { Table } from "./TableComponent";

export function RightImg(props) {

    const roundImg = {
        width: "calc(300px + 5vw)",
        height: "calc(300px + 5vw)",
        borderRadius: "50%",
    }

    const normal = {
        width: "auto"
    }
    return (
        <div className="container-fluid" dir={props.dir ? props.dir : "ltr"}>
            <div className="row mt-3">
                <div className={`m-auto px-0 px-md-3 ${props.cols ? props.cols : "col-md-5 col-lg-4 col-12 "}`} dir="ltr">
                    <div className="d-flex justify-content-center">
                        {props.img ?
                            <img
                                src={props.img}
                                className="img-fluid"
                                alt={props.title}
                                title={props.title}
                                style={props.roundImg ? roundImg : normal}
                            />
                            :
                            props.rightText ? props.rightText : null
                        }
                    </div>
                </div>

                <div className={` m-auto px-0 px-md-3 py-3 ${props.cols1 ? props.cols1 : 'col-md-7 col-lg-8 col-12'}`} dir="ltr">
                    <b>
                        {props.heading}
                    </b>

                    {props.tableData ?
                        <Table tableData={props.tableData} />
                        :
                        null
                    }
                    {
                        props.data ?
                            props.data : null
                    }

                </div>
            </div>

        </div>
    )
}
