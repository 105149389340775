import { useState } from "react";
import {
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsHeart,
  BsHeartFill,
} from "react-icons/bs";

import { IoHappyOutline, IoHappySharp } from "react-icons/io5";
import style from "../../css/PostCard.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function PostLikeIcons({ postId, like }) {
  const navigate = useNavigate();
  const [likeCounter, setLikeCounter] = useState(
    localStorage.getItem("userId") ? like : ""
  );

  // const [showModal, setShowModal] = useState(false);
  const [userRec, setUserRec] = useState();

  const sendLike = (id) => {
    const data = {
      postId: postId,
      userId: localStorage.getItem("userId"),
      like: id,
    };

    axios
      .post("userlike", data)
      .then((res) => {
        setUserRec(res.data);
        setLikeCounter(res.data.like);
      })
      .catch((err) => console.log(err));
  };

  const setLikeHandler = (newVal) => {
    localStorage.getItem("userId")
      ? console.log(sendLike(newVal))
      : navigate("/login");
  };

  return (
    <div className={style.postCardFooter}>
      <div className="d-flex my-auto">
        <div className={style.iconCard}>
          {likeCounter === 1 ? (
            <BsHeartFill
              className={style.pointer}
              onClick={() => setLikeHandler(1)}
            />
          ) : (
            <BsHeart
              className={style.pointer}
              onClick={() => setLikeHandler(1)}
            />
          )}
        </div>
      </div>

      <div className="d-flex my-auto">
        <div className={style.iconCard}>
          {likeCounter === 2 ? (
            <BsHandThumbsUpFill
              className={style.pointer}
              onClick={() => setLikeHandler(2)}
            />
          ) : (
            <BsHandThumbsUp
              className={style.pointer}
              onClick={() => setLikeHandler(2)}
            />
          )}
        </div>
      </div>

      <div className="d-flex my-auto">
        <div className={style.iconCard}>
          {likeCounter === 3 ? (
            <IoHappySharp
              className={style.pointer}
              onClick={() => setLikeHandler(3)}
            />
          ) : (
            <IoHappyOutline
              className={style.pointer}
              onClick={() => setLikeHandler(3)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
