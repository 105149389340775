import { HiOutlineMail } from 'react-icons/hi';
import styles from "../../css/Custom.module.css";

export function Email(props) {
    return (

        <div className="d-flex">

            <div className='d-flex'>

                <HiOutlineMail size="18" className='mt-1' />

                <span className={`d-none ${props.mailText ? props.mailText : 'd-md-flex'}`}  >
                    &nbsp; Mail Us:
                </span>

            </div>
            &nbsp;
            <div className='d-flex'>
                <span>
                    {props.email1 ?
                        <a className={`${styles.a} ${props.textColor ? props.textColor : "text-dark"}`} href={`mailto: ${props.email1}`}>  {props.email1}  </a>
                        : ''}
                </span>

                {props.email2 ? <a href={`mailto: ${props.email2}`}> , {props.email2}  </a>
                    : ''}

            </div>
        </div>
    )
}

