import Helmet from "react-helmet";

export function MetaKerala(props) {
    return (

        <Helmet>
            <meta
                name="geo.region"
                content={
                    props.region ? props.region : `IN-KL`
                } />
            {/* City and State */}

            <meta
                name="geo.placename"
                content={
                    props.placename ? props.placename : `Varkala, Kerala`
                } />
            {/* City And State */}

            <title>
                {
                    props.title ? props.title : `Best Yoga School in Kerala | Yoga In Kerala`
                }

            </title>

            <meta name="geo.position" content={props.position ? props.position : '9.788839240475891, 76.66260033484168'} />
            {/* latitude; longitude */}

            <meta name="ICBM" content={props.position ? props.position : '9.788839240475891, 76.66260033484168'} />
            {/* latitude; longitude */}

            <meta
                name="title"
                content={props.title ? props.title : `Best Yoga school in kerala | Best Yoga Academy `} />

            <meta
                name="description"
                content={props.description ? props.description : `Best Yoga School In Rishikesh. Pankaj Yogpeeth Cover These Topics In Their Courses: Meditation, Hath Yoga, Asana, Shatkarma, Pranayama And Much More`}
            />

            {/* "Pankaj Yogpeeth is the Best Yoga School in Kerala - our Academy Offers 200 Hour, 300 hours, and 500 Hour Teacher Training Courses in Kerala" */}

            <meta
                name="msapplication-starturl"
                content="https://www.pankajyogpeeth.com/" />

            <link rel='canonical' href='https://www.pankajyogpeeth.com' />

            <meta
                name="DC.title"
                content={props.description ? props.description : `Best Yoga School in Kerala
            | Pankaj Yogpeeth - Academy`} />


            <meta
                name="keywords"
                content="
                100 hours yoga teacher training course,
                200 hours yoga teacher training course,
                300 hours yoga teacher training course,
                500 hours yoga teacher training course,
                best yoga institute in Kerala
                best yoga institute,
                pankaj yogpeeth,
                yoga in Kerala,
                yoga,
                pranayama,
                yoga techer training,
                best yoga institute in Kerala,
                meditation,
                yoga phylosophy,
                yoga in Kerala India,
                yoga classes,
                yoga in India,
                yoga institute,
                ashtanga yoga,
                master in yoga,
                master course in yoga,
                yoga for begginer,
                yoga for teahers,
                yoga for seniors,
                yoga for juniors,
                yoga for adults,
                yoga for girls,
                yoga for woman,
                yoga for weman,
                yoga for man,
                yoga for old man,
                yoga for old men,
                yoga for old woman,
                yoga for old weman,
                " />

            <meta
                name="image"
                content={props.imageUrl ? props.imageUrl : ''} />


            <meta itemprop="name"
                content="Yoga School in Kerala - Pankaj Yogpeeth" />

            <meta itemprop="description"
                content={props.description ? props.description : "Pankaj Yogpeeth is the Best Yoga School in Kerala - our Academy Offers 100 Hours, 200 hours, 300 hours and 500 Hours Teacher Training Courses in Kerala."} />

            <meta itemprop="image"
                content={props.imageUrl ? props.imageUrl : ''} />


            <meta property="og:title"
                content="Yoga School in Kerala - Pankaj Yogpeeth" />

            <meta property="og:description"
                content="Pankaj Yogpeeth offers Residential 100 Hour, 200 Hour Yoga TTC." />

            <meta property="og:image"
                content={props.imageUrl ? props.imageUrl : ''} />

            <meta property="og:url"
                content="https://www.pankajyogpeeth.com/" />

            <meta property="og:site_name"
                content="Yoga School in Kerala - Pankaj Yogpeeth" />


            <meta name="twitter:card"
                content="summary" />

            <meta name="twitter:title"
                content={
                    props.title ? props.title : `Best Yoga School in Kerala
                        | Pankaj Yogpeeth - Academy`
                } />

            <meta name="twitter:description"
                content={props.description ? props.description : `Best Yoga School in Kerala
            | Pankaj Yogpeeth - Academy`} />

            <meta name="twitter:image:src"
                content={props.imageUrl ? props.imageUrl : 'https://www.pankajyogpeeth.com/images/gallery-memories/13.jpg'} />

        </Helmet>
    )
}
