export function Table(props) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-striped table-hover">
                    <thead>
                        {
                            props.tableData.map((item, index) =>
                                index < 1 ?
                                    <tr key={index}>
                                        {
                                            item.map((data, i) =>
                                                <th className="my-auto" key={i}>
                                                    {data}
                                                </th>
                                            )
                                        }
                                    </tr>
                                    :
                                    ""
                            )
                        }

                    </thead>

                    {/* tbody */}
                    <tbody>
                        {
                            props.tableData.map((item, i) =>
                                i > 0 ?
                                    <tr key={i}>
                                        {
                                            item.map((data, i) =>
                                                <td key={i}>
                                                    <div className="my-auto">
                                                        {data}
                                                    </div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                    :
                                    ""
                            )
                        }

                    </tbody>


                    {/* tbody */}

                </table>

            </div>
        </div>

    )
}