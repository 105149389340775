
import { BiChevronDown } from 'react-icons/bi';
export function Collapse(props) {
    return (
        <div className="card shadow py-0 my-3 px-0" data-bs-toggle="collapse"
            data-bs-target={"#" + props.id}
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample">
            <div className="card-body">
                <div>
                    <div className="d-flex justify-content-between">
                        <h3 className="my-auto">
                            {props.heading}
                        </h3>
                        <h3 className="my-auto">
                            <BiChevronDown />
                        </h3>
                    </div>

                </div>
                <p className="collapse pb-0" id={props.id}>
                    {props.text}
                </p>
            </div>

        </div>
    )
}

