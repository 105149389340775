import React from 'react';
import { NavLink } from 'react-router-dom';
import Styles from "../../css/Custom.module.css";

function OutLineCard(props) {
    return (
        <NavLink to={props.href} className={Styles.OutLineCard}>
            <div>
                <h1 className={`${Styles.title} text-capitalize`}>
                    {props.title ? props.title : "Pls Write Something"}
                </h1>
                
                <div className={Styles.heading1}>
                    Enroll Now
                </div>  
            </div>
        </NavLink>
    )
}

export default OutLineCard