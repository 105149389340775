import { HiOutlinePhoneIncoming } from 'react-icons/hi';
import { SiWhatsapp } from 'react-icons/si';

import styles from "../../css/Custom.module.css";

export function Callme(props) {
    return (
        <div className="d-flex">
            <div className='d-inline-flex'>
                <SiWhatsapp
                    size="17"
                    className='mt-1'
                />
            </div>
            &nbsp;
            <div className={`d-flex`}>
                <span>
                    {props.numb1 ?
                        <a className={`${styles.a} ${props.textColor ? props.textColor : "text-dark"}`}
                            href={`https://wa.me/${props.numb1}?text=${props.whatsappText ? props.whatsappText : 'Hello, How can i book a seat offline?'}`}
                            target="_blank"
                        >
                            {props.numb1}
                        </a>
                        : ''}
                </span>

                {props.numb2 ? <a href={`tel: ${props.numb2}`} className={`${styles.a} text-dark`}> , {props.numb2}  </a>
                    : ''}

            </div>
        </div>
    )
}

