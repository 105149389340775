import Helmet from "react-helmet";

export function MetaNepal(props) {

    return (
        <Helmet>
            <meta
                name="geo.region"
                content={
                    props.region ? props.region : `NP-PKR`
                } />
            {/* City and State */}

            <meta
                name="geo.placename"
                content={
                    props.placename ? props.placename : `Pokhra, Nepal`
                } />
            {/* City And State */}

            <title>
                {
                    props.title ? props.title : `Best Yoga School in Nepal
                        | Pankaj Yogpeeth - Academy`
                }

            </title>

            <meta name="geo.position" content={props.position ? props.position : '28.210476194394715, 83.95781467854027'} />
            {/* latitude; longitude */}

            <meta name="ICBM" content={props.position ? props.position : '28.210476194394715, 83.95781467854027'} />
            {/* latitude; longitude */}

            <meta
                name="title"
                content={props.title ? props.title : `Best Yoga School in Nepal
                        | Pankaj Yogpeeth - Academy`} />

            <meta
                name="description"
                content={props.description ? props.description : `Best Yoga School in Nepal
            | Pankaj Yogpeeth - Academy`}
            />

            <meta
                name="msapplication-starturl"
                content="https://www.pankajyogpeeth.com/" />

            <link rel='canonical' href='https://www.pankajyogpeeth.com' />

            <meta
                name="DC.title"
                content={props.description ? props.description : `Best Yoga School in Nepal
            | Pankaj Yogpeeth - Academy`} />


            <meta
                name="keywords"
                content="
                100 hours yoga teacher training course,
                200 hours yoga teacher training course,
                300 hours yoga teacher training course,
                500 hours yoga teacher training course,
                best yoga institute in Nepal
                best yoga institute,
                pankaj yogpeeth,
                yoga in Nepal,
                yoga,
                pranayama,
                yoga techer training,
                best yoga institute in Nepal,
                meditation,
                yoga phylosophy,
                yoga in Nepal India,
                yoga classes,
                yoga in India,
                yoga institute,
                ashtanga yoga,
                master in yoga,
                master course in yoga,
                yoga for begginer,
                yoga for teahers,
                yoga for seniors,
                yoga for juniors,
                yoga for adults,
                yoga for girls,
                yoga for woman,
                yoga for weman,
                yoga for man,
                yoga for old man,
                yoga for old men,
                yoga for old woman,
                yoga for old weman,
                kundlini Yoga,
                                " />

            <meta
                name="image"
                content={props.imageUrl ? props.imageUrl : 'http://localhost:3000/assets/images/new/banners/nepal-100.jpg'} />


            <meta itemprop="name"
                content="Yoga School in Nepal - Pankaj Yogpeeth" />

            <meta itemprop="description"
                content={props.description ? props.description : "Best yoga school in nepal we offeres 100 hours, 200 hours, 300 hours, and 500 hours yoga certofied classes. after completing course you will get certificate of Pankaj Yogpeeth."} />

            <meta itemprop="image"
                content={props.imageUrl ? props.imageUrl : 'http://localhost:3000/assets/images/new/banners/nepal-100.jpg'} />


            <meta property="og:title"
                content="Yoga School in Nepal - Pankaj Yogpeeth" />

            <meta property="og:description"
                content={props.description ? props.description : "Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Nepal. Learn yoga with experts with highly experienced teachers."} />

            <meta property="og:image"
                content={props.imageUrl ? props.imageUrl : 'http://localhost:3000/assets/images/new/banners/nepal-100.jpg'} />

            <meta property="og:url"
                content={props.ogUrl ? props.ogUrl : "https://www.pankajyogpeeth.com/nepal"} />

            <meta property="og:site_name"
                content="Best Yoga School in Nepal - Pankaj Yogpeeth" />


            <meta name="twitter:card"
                content={props.description ? props.description : "Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Nepal. Learn yoga with experts with highly experienced teachers."} />

            <meta name="twitter:title"
                content={
                    props.title ? props.title : `Best Yoga School in Pokhara in Nepal
                        | Pankaj Yogpeeth - Academy`
                } />

            <meta name="twitter:description"
                content={props.description ? props.description : "Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Nepal. Learn yoga with experts with highly experienced teachers. "} />

            <meta name="twitter:image:src"
                content={props.imageUrl ? props.imageUrl : 'http://localhost:3000/assets/images/new/banners/nepal-100.jpg'} />
        </Helmet>

    )
}
