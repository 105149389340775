import axios from "axios";
import React, { useState } from "react";
import {
  BsChatTextFill,
  BsFillCameraVideoFill,
  BsImageFill,
} from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";

import style from "../../css/Authentication.module.css";
import FileSelector from "./FileSelector";

function FormUploadCard() {
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState("");

  const [record, setRecord] = useState({
    description: "",
    subject: "",
    type: "",
    id: localStorage.getItem("userId"),
  });

  var url = file ? URL.createObjectURL(file) : "";

  const fileChangeHandler = (e, type) => {
    setFile(e.target.files[0]);
    setFileType(type);
    setRecord({ ...record, type: type });
  };

  const modalShow = () => {
    setFile("");
    setFileType("");
  };

  const submit = () => {
    const data = new FormData();
    file && data.append("file", file);
    data.append("data", JSON.stringify(record));

    axios
      .post("insertBlogs", data)
      .then((e) => console.log(e))
      .catch((err) => console.log(err));
  };
  console.log({ record });
  //   {
  //     /* <PostAxios
  //   url="insertBlogs"
  //   data={data}
  //   response={(e) => console.log(e)}
  //   error={(e) => setError(e)}
  // />; */
  //   }

  return (
    <div className="col-12 col-md-8 col-lg-5 mx-auto mt-5">
      <div
        className={`card mt-5 border-0 ${style.pointer} ${style.uploadCard}`}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <div
          className={`card-body  ${style.uploadFormWrapper}`}
          onClick={modalShow}
        >
          <div className={`d-flex ${style.userImage}`}>
            <img src="./assets/images/chakra/chakra.webp" alt="" />
            <input
              type="text"
              className="form-control"
              placeholder="Say Something"
            />
          </div>

          <div className="d-flex justify-content-between mt-1">
            <div className="form-group">
              <label htmlFor="for" className={`d-flex ${style.pointer}`}>
                <BsImageFill className="mt-1" />
                <div style={{ marginLeft: 6, marginTop: 1 }}> Image </div>
              </label>
              <input
                type="file"
                className="d-none"
                id="for"
                accept="image/*"
                onChange={(e) => fileChangeHandler(e, "image")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="video" className={`d-flex ${style.pointer}`}>
                <BsFillCameraVideoFill className="mt-1" />
                <div style={{ marginLeft: 6, marginTop: 1 }}> Video </div>
              </label>
              <input
                type="file"
                className="d-none"
                id="video"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={(e) => fileChangeHandler(e, "video")}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="text"
                className={`d-flex ${style.pointer}`}
                onClick={modalShow}
              >
                <BsChatTextFill className="mt-1" />
                <div style={{ marginLeft: 6, marginTop: 1 }} id="text">
                  Text
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Bootstrap */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: 400, overflowY: "auto" }}
            >
              <div className="form-group mb-1">
                <label htmlFor="title"> Subject </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  maxLength={20}
                  onChange={(e) => {
                    setRecord({ ...record, subject: e.target.value });
                  }}
                />
                <span className="small text-danger">
                  Subject limit 20 character
                </span>
              </div>
              <div className="form-group mb-1">
                <label htmlFor="des"> Description </label>
                <textarea
                  id="des"
                  className="form-control"
                  cols="30"
                  rows="5"
                  onChange={(e) => {
                    setRecord({ ...record, description: e.target.value });
                  }}
                ></textarea>
                <span className="text-danger small">
                  Describe as much as you can
                </span>
              </div>

              <div className="text-center">
                {file &&
                  (fileType === "image" ? (
                    <img
                      src={url}
                      className="img-fluid"
                      alt=""
                      style={{ maxHeight: 300 }}
                    />
                  ) : (
                    <video src={url} controls className="img-fluid" />
                  ))}
              </div>
            </div>
            <div className="modal-footer w-100 py-0 border-0">
              <div className="d-flex justify-content-between w-100">
                <FileSelector
                  icon={<BsImageFill className="mt-1" />}
                  type="file"
                  label="Image"
                  fileType="image"
                  id="image1"
                  clickHandler={(e) => fileChangeHandler(e, "image")}
                />
                <FileSelector
                  icon={<BsImageFill className="mt-1" />}
                  type="file"
                  label="Video"
                  fileType="video"
                  id="video1"
                  clickHandler={(e) => fileChangeHandler(e, "video")}
                />
                <div className="my-auto">
                  <button
                    className="btn"
                    disabled={file !== "" || file !== undefined ? true : false}
                  >
                    Delete
                  </button>
                </div>
                <div className="my-auto">
                  <button
                    className="btn"
                    onClick={submit}
                    disabled={
                      record["subject"] === "" ||
                      record["subject"] === undefined ||
                      record["description"] === "" ||
                      record["description"] === undefined
                        ? true
                        : false
                    }
                  >
                    <FaPaperPlane className="p-0" /> Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Bootstrap */}
    </div>
  );
}

export default FormUploadCard;
