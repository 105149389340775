import { CheckCard } from "./CheckCard";
import { Heading } from "./Heading";
import { RightImg } from "./RightImg";

export function Food() {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Heading heading={<b> Special Food For Yogis </b>} />
      </div>
      <div className="col-12">
        <div className="row">
          <RightImg
            cols="col-md-12 col-lg-5"
            cols1="col-md-12 col-lg-7"
            dir="ltr"
            img="../assets/images/pankaj/accomodation/pankajyogpeethfood.jpg"
            data={
              <div className="px-3">
                <p>
                  Our food is known all over the globe for its taste and
                  variety. All meals are prepared hygienically by an expert chef
                  and the vegetables used are organically grown. Only vegetarian
                  meals are served and are included in the fees of our 100 hour
                  yoga teacher training in India course.
                </p>
                <p>
                  The food we eat is not only necessary for our physical well
                  being but also for good mental health. The classification of
                  food in the world of yoga is a little different from the
                  scientific world. Instead of classifying food in proteins,
                  fats or carbohydrates the yogic food is classified as - Satva,
                  Rajas and Tamas. Tamasic food makes us feel lazy or sluggish.
                  Rajasic food makes you energetic and may cause restlessness
                  whereas sattvic food makes you feel energetic, enthusiastic
                  and light.
                </p>
                <h3>The following meals are included:</h3>

                <div className="row">
                  <CheckCard
                    cols="col-11 col-md-6"
                    data={["Breakfast", "Dinner"]}
                  />
                </div>

                <h3 className="mt-3">
                  The following dietary requirement(s) are served and/or
                  catered:
                </h3>

                <div className="row">
                  <CheckCard
                    cols="col-11 col-md-6"
                    data={[
                      "Fruitarian",
                      "Organic",
                      "Yogic",
                      "Ayurvedic",
                      "Herbal Tea",
                      "Ayurvedic Tea",
                      "Raw Food",
                    ]}
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
