import Styles from "../../css/Custom.module.css";
export function Banner(props) {
  const backgroundImg = {
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover",
    height: "calc(200px + 20vw)",
    minWidth: "calc(100vw - 17px)",
    display: "grid",
    placeItem: "center",
    marginTop: "calc(95px + 1vw)",
    backgroundColor: "red",
    boxSizing: "border-box !important",
  };

  const bgVar = {
    width: "100%",
  };

  return (
    <div>
      <div
        className={Styles.bannerWrapper}
        style={props.bgImg ? backgroundImg : bgVar}
      >
        {!props.img && (
          <video src={props.video} className="img-fluid" controls></video>
        )}

        {!props.video && (
          <img
            src={props.img}
            alt={props.title ? props.title : "Best Yoga School"}
            title={props.title}
            className="img-fluid mt-4"
          />
        )}
        <div>
          <h1>{props.city}</h1>
        </div>
      </div>
    </div>
  );
}
