import { NavLink } from "react-router-dom";
import { RiArrowDropRightLine } from "react-icons/ri";
import Styles from "../../css/Custom.module.css";

export function Steppers(props) {
  return (
    <div className={`${Styles.steppers}`} key="abcd999">
      <NavLink to="/" key="one">
        Home
      </NavLink>
      {props.navlinks
        ? props.navlinks.map((item, index) => (
            <div key={`${item}`}>
              <div className="d-flex">
                <span className={Styles.pipe}>
                  <RiArrowDropRightLine className={Styles.stepperArrow} />
                </span>

                <div className="d-none d-md-flex" key={item + index}>
                  <NavLink
                    className="text-capitalize"
                    to={item[0]}
                    title={item[2]}
                    disabled
                  >
                    {index < 1 ? item[1] : item[2]}
                  </NavLink>
                </div>

                <div
                  className="d-flex d-md-none"
                  key={Math.floor(Math.random() * 1000)}
                >
                  <NavLink
                    className="text-capitalize"
                    to={item[0]}
                    title={item[2]}
                    disabled
                  >
                    {item[1]}
                  </NavLink>
                </div>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
}
