import style from "../../css/PostCard.module.css";

import { useState } from "react";
import { PostLikeIcons } from "./PostLikeIcons";
import { PostEditDelete } from "../../screens/blogs/PostShowEdit";

export function PostCard({
  profilePic,
  username,
  occupation,
  userId,
  img,
  title,
  date,
  fileType,
  text,
  postId,
  like,
  likeCount,
}) {
  const [toggleButtn, setToggleButtn] = useState(500);
  const [readMoreText, setReadMoreText] = useState("Read More");

  const SessionId = localStorage.getItem("userId");

  const readMoreHandler = (length) => {
    setToggleButtn(toggleButtn === 500 ? length : 500);
    setReadMoreText(readMoreText === "Read More" ? "Read Less" : "Read More");
  };

  return (
    <div className={style.postCard}>
      <div className={style.header}>
        <div className="d-flex">
          {/* profile pic */}
          <div className={style.imgwrapper}>
            <img src={profilePic} alt={username} title={username} />
          </div>
          {/* username */}
          <div className={style.username}>
            <span> {username} </span>
            <span> {occupation} </span>
          </div>
        </div>

        {SessionId === userId && <PostEditDelete userId={userId} />}
      </div>

      <div className={style.postCardBody}>
        {fileType === "image" ? (
          <div className={style.postCardImgWrapper}>
            <img src={img} alt={title} title={title} />
          </div>
        ) : (
          ""
        )}

        {fileType === "video" ? (
          <div className={style.postCardImgWrapper}>
            <video src={img} alt={title} title={title} controls />
          </div>
        ) : (
          ""
        )}

        <div className="d-flex justify-content-between align-middle mt-3 text-capitalize">
          <div className={style.tag}> {title} </div>
          <div className={`${style.date} my-auto`}> {date} </div>
        </div>
        <div className={`${style.textWrapper} p-2`}>
          {text.substring(0, toggleButtn)}

          <span
            className={`text-primary ${style.pointer}`}
            onClick={() => readMoreHandler(text.length)}
          >
            {text.length > 500 && readMoreText}
          </span>
        </div>
        <div className="small py-1 px-2">like {likeCount}</div>
        <PostLikeIcons postId={postId} like={like} />
      </div>
    </div>
  );
}
