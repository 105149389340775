import React, { useEffect } from "react";
import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { MetaForOthers } from "../../screens/meta/MetaForOthers";
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaForOthers
        title="About Pankaj Yogpeeth | 
                Learn Yoga And Enjoy A Week Together |
                www.pankajyogpeeth.com"
        region="IN-RK"
        placename="Rishikesh"
        position="9.788839240475891, 76.66260033484168"
        description="About Pankaj Yogpeeth: One Of The Best Yoga Institute Or School In Rishikesh. You can learn Certified Yoga Course."
      />
      <Banner
        img="../assets/images/pankaj/rishikesh/PankajYogpeeth (50).jpg"
        city="About Us"
      />

      <Heading heading="let's talk about Pankaj Yogpeeth" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8 mx-auto px-5">
            <p>
              Pankaj Yogpeeth shares the traditional Yoga which is SAFE and
              driven by best yoga teachers in India. All our courses are Yoga
              Alliance USA certified courses. The whole intention of starting
              Pankaj Yogpeeth is sharing the Yoga experience we received from
              our teachers. All our teachings are not only for the sake of
              certification but for the great purpose of raising the awareness
              of whole humanity. We want to make sure that whoever practice yoga
              his/her life should a blissful life. At Pankaj yogapith we are
              committed to providing you with the most cost effective courses
              available. Our all inclusive courses provide you with quality
              services at an affordable price.
            </p>
            <h3>What Courses What Do We Offer?</h3>

            <ul>
              <li>
                <b>100 Hours Yoga Teachers Training Course</b>
              </li>
              <li>
                <b>200 Hours Yoga Teachers Training Course</b>
              </li>
              <li>
                <b>300 Hours Yoga Teachers Training Course</b>
              </li>
              <li>
                <b>500 Hours Yoga Teachers Training Course</b>
              </li>
            </ul>

            <p>
              The speciality of the our school is we offered ayurveda and yoga
              courses.
            </p>
            <h3>About Teachers</h3>
            <p>
              Pankaj Yogpeeth is very famous for their teachers who shares the
              root knowledge of yoga with the students who are keen to learn the
              deaper knowledge of yoga.
            </p>
            <div className="mt-4">
              <h3>Why We Build Pankaj Yogpeeth?</h3>
              <p>
                Pankaj Yogpeeth is the group of highly experts techers and whom
                prime focus is sharing their knowledge to their deciple and
                spred healthy wibes to bring the smile and remove negativity to
                the humen and bring the positive changes in their life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
