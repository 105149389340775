import { Banner } from "../../components/util/Banner";
import { RightImg } from "../../components/util/RightImg";
import { Heading } from "../../components/util/Heading";
import { CheckCard } from "../../components/util/CheckCard";
import { MetaForOthers } from "../meta/MetaForOthers";


export function ShriLanka() {
    return (
        <>

            <MetaForOthers
                title="Retreat in Rishikesh | Learn Yoga And Enjoy A Week Together | www.pankajyogpeeth.com"
                region="NP-NPR"
                placename="Rishikesh"
                position='30.123618470767628, 78.32271291310317'
                description="Yoga Retreat In Rishikesh: Enjoy Your Week With Yoga And Healthy Food And Sight Seeing With Accomodation."
            />
            <Banner img="../assets/images/retreat/nepal-retreat123.jpg"
                city={
                    <b>
                        Shri Lanka Retreat
                    </b>
                }
                title="Yoga In Rishikesh"
            />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto px-0">
                        <Heading heading="Yoga Retreat in Sri Lanka" />
                        <p className="text-center">
                            What does yoga retreat means? A yoga retreat is withdrawal to focus on the practice of yoga. A retreat can be solitary and/or local,but often involves a trip to a location that offers group yoga activities. some retreats around the world will offer you a luxury resorts and places with expensive charges. But with us you will experience the traditional practice of yoga with clean and affordable accomadation, where we will offer you some visitings to ashrams,discover beautiful landscapes and the sunrise in mountains. The retreat will help you to maintain the balance between your mind, body and prana. so, we will offer you a practice of yoga asana,meditation and lots of breath work they will bring the calmness and stillness in your mind.
                        </p>
                    </div>

                    <div className="mt-5">
                        <Heading heading="what do we Include In 
                        Retreat?" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/1.webp"
                            title="Yoga Retreat In Sri Lanka"
                            data={<div className="row"> <CheckCard cols="col-12 col-sm-6" data={["Accomodation", "Food", "Traveling", "hearbal Tea", "Yoga Classes", "Sight Seeing", "Pranayam Class", "Meditation Class", "Single Room", "Shared Room"]} /> </div>}
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading="Our Yoga Journey During This Course" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/nepal-yoga.jpg"
                            title="Yoga Retreat In Sri Lanka"
                            data={
                                <p className="px-5">
                                    Discover Sri Lanka with Pankaj Yogpeeth, They called it the island of the tear drop, located towards the south of india. A tropical paradise with endless beaches lined with palm trees, colourful hindus and buddist temples, ancient architecture and lush green jungles. Sri Lanka is the worlds largest tea producer. The landscapes are beautiful with the magical sunsets which makes it a really perfect place. to develop or next retreat and immerse ourselves in this wonderful experience. Date 3rd jan-12th jan, 2022. Includes 9 days of hotel accommodation, Sri wellness in unawatuna, Srilanka, (Breakfast,dinner) Yoga classes Hatha yoga, meditation, pranayama, yin yoga, Asthanga vinyasa, yoga nidra
                                </p>
                            }
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading=" Most Important - Food" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/food3.jpg"
                            heading={<h3>
                                <b>
                                    Most Important Thing is food That We Offers To Our Precious Guests:-
                                </b>
                            </h3>}
                            title="Yoga Retreat In Sri Lanka"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Hygienic", "Healthy", "Protene", "Tasty"]}
                                    />
                                    <p className="mt-4">
                                        Food is the most important element in yoga retreat, this is directly related to our physical and mental strength. we constantly expend energy in various yogic activity, so we fill it with the delicious healthy organic food.These things have an effect on the physical and mental activities of the living being. physical health is also important as much as the mental health. We offer delicious vegan and vegetarian meals with lots of healthy food available according to season. we offers fresh juices and seasonal fruits salad.In retreat we also provide the traditional meaLs according to the locations Or the places we are conducting a yoga retreat. we also respect your needs of particular food and will help in arrange with all support.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>

                    <div className="mt-5" >
                        <Heading heading="Stay" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/room.jpg"

                            title="Yoga Retreat In Sri Lanka"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Clean", "Hygienic", "Single/Shared", "Ac/Non-Ac"]}
                                    />
                                    <p className="mt-4">
                                        HOTEL Sri wellness Charming little hotel located on the South coast of Sri Lanka, It will be our home during this Yoga retreat. Bedrooms surrounded by nature, Yoga hall with wonderful views, massage treatments, outdoor pool, ... they will help us to live with more presence, calm and well-being this stay. Sri Yoga Shala will welcome you to return to yourselves, to discover your essence and connect with it.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>


                </div>

            </div>
        </>
    )
}