import Style from "../../css/Custom.module.css";
export function Fullpagetext(props) {

    const img = {
        background: props.imgUrl ? `url(${props.imgUrl})` : '',
        backgroundColor: props.varColor ? props.varColor : props.color,
        backgroundBlendMode: props.blend,
        backgroundize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "noRepeat",
    }

    return (
        <div className={`container-fluid ${Style.mt5}`}>

            <div className="row">
                <div className={Style.fullPageText} style={img}>
                    <div className="col-12 px-0">
                        <div className={Style.chakraText}>
                            {props.aboutColor}
                        </div>
                        <h1 title={props.title}>
                            {props.title}
                        </h1>
                    </div>
                    <div className="col-12 px-0">
                        {props.text.map((item, index) =>
                            <p key={index}>
                                {item}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}
