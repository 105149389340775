import { RightImg } from "../../components/util/RightImg";
import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { useEffect } from "react";

export function Teachers() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Banner
              img="../assets/images/pankaj/teachers/t13.jpg"
              city="Teachers"
            />
          </div>

          <div className="col-12 px-3 px-md-5 mt-5">
            <Heading heading="Teachers" />

            <RightImg
              img="../assets/images/pankaj/teachers/pankaj.jpeg"
              roundImg="round"
              heading={
                <h3>
                  <b> Yogacharya Pankaj Ji </b>
                </h3>
              }
              data={
                <>
                  <p>
                    Yogi Pankaj ji born on the pious land of Rishikesh, and
                    since childhood, he was fascinated by the goodness of yoga.
                    Since a very young age, he developed a passion for yoga
                    practice. Yogi pankaj has been sharing his knowledge of yoga
                    since more the 10 years around the world.
                  </p>

                  <p>
                    He has been sharing in many yoga retreats and yoga teacher
                    training courses. He is one of the experience teacher in
                    rishikesh who completed his master degree in yoga and
                    holistic health, also he continued learning in many ashram
                    in rishikesh.
                  </p>
                  <p>
                    He is sharing classes of hatha yoga, alignment adjustment,
                    teaching methodology, anatomy.
                  </p>
                </>
              }
            />

            <RightImg
              img="../assets/images/pankaj/teachers/3.jpg"
              roundImg="round"
              heading={
                <h3 dir="rtl">
                  {" "}
                  <b> Swami Atma ji </b>
                </h3>
              }
              data={
                <>
                  <p>
                    Swami Atma Jnanananda Giri is a Homeopathic doctor from
                    Kerala, residing in Rishikesh since 11 years.
                  </p>

                  <p>
                    He learned yoga meditation from Swami Rama Sadhaka Grama
                    ashram, Vedanta (knowledge of Indian Philosophy) from
                    Dayananda ashram, and is currently leading students on the
                    path of yoga and meditation.
                  </p>
                  <p>
                    He offers insightful lectures on these topics as well as
                    philosophy, and draws his inspiration of enlightenment
                    practices from the Mandukya Upanishad.
                  </p>
                  <p>
                    The students are guided to a state of relaxation through
                    Yoga Nidra and are taught exercises to optimize the
                    functioning of joints and glands and purification of the
                    nadis, to help the prana flow throughout the body.
                  </p>
                  <p>
                    The Bhagavad Gita is presented as a scripture beyond
                    religion and its relationship to yoga is explored. Learners
                    are brought to an understanding of yoga as a way of life and
                    a means of achieving balance within themselves in a
                    stressful and fast-paced existence, rather than exclusively
                    as physical exercise.
                  </p>
                </>
              }
              dir="rtl"
            />

            <div className="mt-5">
              <RightImg
                img="../assets/images/pankaj/teachers/kavita.jpeg"
                roundImg="round"
                heading={
                  <h3>
                    {" "}
                    <b> Kavita Ji </b>{" "}
                  </h3>
                }
                data={
                  <>
                    <p>
                      Being Born And Raised In A Spiritually Well-Established
                      Family In Karnataka, Kavitha Ji’s Upbringing Was Instilled
                      With All The Values And Teachings Of Basavanna. She Is A
                      RYT 200,300 Pranayama, Philosophy, mantra chanting,
                      shatkarma and yoga nidra Teacher
                    </p>
                  </>
                }
              />
            </div>

            <div className="mt-5">
              <RightImg
                dir="rtl"
                img="../assets/images/pankaj/teachers/nitin.jpg"
                roundImg="round"
                heading={
                  <h3 dir="rtl" className="mx-5">
                    {" "}
                    <b> Yogi Nitin Ji </b>
                  </h3>
                }
                data={
                  <div>
                    <p>
                      Yogi Nitin ji is a talented wonderful asana teacher, who
                      spend years of practice in rishikesh under many teachers
                      and gurus. He completed his study in the yoga city
                      rishikesh.
                    </p>

                    <p>
                      He offers energetic practices of asana throughout his
                      class. He is expert in ashtanga and vinyasa flow practice.
                    </p>

                    <p>
                      Nitin ji is originally from rishikesh, and he is sharing
                      his knowledge with students around the world since more
                      than 5 years.
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
