import { useEffect } from "react";
import { Banner } from "../../components/util/Banner";
import { Heading } from "../../components/util/Heading";
import { Courses } from "../../components/util/Courses";
import { InfoCard } from "../../components/util/InfoCard";

import { Steppers } from "../../components/util/Steppers";

import Styles from "../../css/Custom.module.css";

import { QuestionAnswer } from "../../components/util/QuestionAnswer";
import WhatDoWeOffer from "../../components/util/WhatDoWeOffer";

import { MetaNepal } from "../meta/MetaNepal";
import { DailySchedule } from "../../components/util/DailySchedule";
import { Expect } from "../../components/util/Expect";

export function Nepal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaNepal
        title="Best Yoga School In Nepal | Best Yoga Academy In Nepal"
        description="Best Yoga School In Nepal. You Can Learn Yoga To  Our Team Of Experts They Are Professionally Practicing Yoga More Than 10 Years. You Will Get Life Time Support"
        placename="Nepal"
        ogUrl="https://www.pankajyogpeeth.com/nepal"
        imgUrl="https://pankajyogpeeth.com/assets/images/new/banners/nepal-100.jpg"
      />

      <div className="container-fluid">
        {/* steppers */}
        <Steppers navlinks={[["/nepal", "Nepal"]]} />
        <div className="row">
          {/* Banner top */}
          <div className="col-12 px-0">
            <Banner
              img="../assets/images/pankaj/Nepal/PankajYogpeeth (73).jpg"
              city="Yoga In Nepal"
              title="Best Yoga School In Nepal"
            />
          </div>

          <div className="col-12 col-md-10 mx-auto px-4">
            <Heading
              heading={<b>Yoga In The City Of Nepal (Land OF Buddha)</b>}
            />
            <p className="text-center">
              The land of buddha Nepal lying along the southern slopes into the
              himalayas mountain range situated north of india in the himalayas
              where it is very famous for its hiking or trekking in the world
              famous peak the "Mount Everest”and the land of buddha “Lumbini"
              the land where you can also experience many famous festival and
              their tradional meals "Daal Bhaat” and the world famous city
              Pokhara also known as the city of lake with its mesmerising nature
              and is perfect place to dvelop your inner teacher.
            </p>
          </div>

          <div className="col-12 px-3">
            <Heading heading={<b>Upcoming Yoga Teacher Training in Nepal</b>} />
          </div>

          <Courses
            img="./assets/images/pankaj/Nepal/PankajYogpeeth (3).JPG"
            title="Best Yoga School In Nepal"
            heading="100 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "100 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 1000$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1100$ for Single Accomodation,
              </span>,
            ]}
            link="/nepal/100-hours-yoga-teacher-training-course-in-nepal"
            bookNowBtn="book now"
          />

          <Courses
            img="./assets/images/pankaj/Nepal/PankajYogpeeth (3).jpg"
            title="Best Yoga School In Nepal"
            heading="200 hours yoga teacher training course"
            btnText="Know More"
            text={[
              "200 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 1250$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1500$ for Single Accomodation,
              </span>,
            ]}
            link="/nepal/200-hours-yoga-teacher-training-course-in-nepal"
            dir="rtl"
            bookNowBtn="book now"
          />

          <Courses
            img="./assets/images/pankaj/Nepal/PankajYogpeeth (53).jpg"
            title="Best Yoga School In Nepal"
            heading="300 hours yoga teacher training course"
            btnText="Know More"
            link="/nepal/300-hours-yoga-teacher-training-course-in-nepal"
            text={[
              "300 Hours Teacher Training Course",
              <span className="">
                <b> Price: </b> &nbsp; 1350$ for Shared Accomodation,
              </span>,
              <span className="">
                <b> Price: </b> &nbsp; 1600$ for Single Accomodation,
              </span>,
            ]}
            bookNowBtn="book now"
          />

          <Courses
            dir="rtl"
            img="./assets/images/chakra/chakra.webp"
            title="Best Yoga School In Nepal"
            heading="500 hours yoga teacher training course"
            link="/nepal/500-hours-yoga-teacher-training-course-in-nepal"
            text={[
              "500 Hours Teacher Training Course",
              <h3 className="text-primary">Comming Soon</h3>,
            ]}
          />
        </div>

        {/* schedule */}

        <div className="row">
          <div className="col-12 mt-4">
            <Heading heading={<b>Facts About Yoga In Nepal</b>} />
          </div>

          <div className={Styles.wrapper}>
            <InfoCard
              img="./assets/images/pankaj/quotes/nepal_adventure.jpg"
              title="Adventure In Nepal"
              textTitle="Best Yoga School In Nepal"
              text="Feed your enthusiasm for the whole lot in your bucket list because it is one of the best countries for adventure and extreme sports tourism. Be it paragliding, white-water rafting, kayaking, high altitude marathons, bungee jumping, and mountain biking, or mountaineering, Nepal is the perfect place to tick off these life experiences."
            />

            <InfoCard
              img="./assets/images/pankaj/quotes/nepal_mountain.jpg"
              title="Yoga In Nepal"
              textTitle="Best Yoga School In Nepal"
              text="Nepal has been tagged as the ‘ceiling of the world’ for being home to eight of the ten world’s highest mountains including Mount Everest, Kanchenjunga, Lhotse, Makalu, Cho Oyo, Dhaulagiri etc. A significant portion of the Himalayas is in Nepal."
            />

            <InfoCard
              img="./assets/images/pankaj/quotes/buddha.jpg"
              title="Buddha Born In Nepal"
              textTitle="Best Yoga School In Nepal"
              text="Gautama Buddha was an ascetic and spiritual teacher of South Asia who lived during the latter half of the first millennium BCE. He was the founder of Buddhism and is revered by Buddhists as an enlightened being who taught a path to freedom from ignorance, craving, rebirth and suffering."
            />
          </div>
        </div>

        <DailySchedule img="../assets/images/pankaj/vertical/pankajyogpeeth (1).jpg" />
        <WhatDoWeOffer />
        <Expect
          dir="rtl"
          img="../assets/images/pankaj/vertical/pankajyogpeeth (3).jpg"
        />
        <QuestionAnswer />
      </div>
    </>
  );
}
