import { useState } from "react";
import style from "../../css/Custom.module.css";

export function FloatingButton({
  title3,
  price3,
  title2,
  price2,
  book,
  now,
  url3,
  url2,
}) {
  const [cardPosition, setCardPosition] = useState("calc(1px - (76px + 3vw))");

  const showOtherCard = () => {
    setCardPosition(
      cardPosition === "calc(1px - (76px + 3vw))"
        ? "0px"
        : "calc(1px - (76px + 3vw))"
    );
  };

  return (
    <div className={style.floatingButton}>
      <a
        href={url3}
        target="__newtab"
        className={style.circle}
        style={{
          marginBottom: cardPosition,
        }}
      >
        <span>{title3}</span>
        <span>{price3}</span>
      </a>

      <a
        href={url2}
        target="__newtab"
        className={style.circle}
        style={{
          marginBottom: cardPosition,
        }}
      >
        <span>{title2}</span>
        <span>{price2}</span>
      </a>

      <div className={style.circle} onClick={showOtherCard}>
        <span>{book}</span>
        <span>{now}</span>
      </div>
    </div>
  );
}
