import { Collapse } from "./Collapse";
import { Heading } from "./Heading";
export function QuestionAnswer() {
  return (
    <div className="row">
      <div className="col-12 mt-3 px-4">
        <Heading heading="Question Answer" />
        <Collapse
          id="subject"
          heading="What subject we covering during Yoga Teacher Training?"
          text={
            <>
              <h4>Practice Of Asana</h4>
              <p>
                <ul>
                  <li>Hatha Yoga</li>
                  <li>Ashtanga Vinyasa</li>
                  <li> Alignment And Adjustment</li>
                </ul>
              </p>
              <h5>Philosophy</h5>
              <h5>Meditation</h5>
              <h5>Pranayama</h5>
              <h5>Mantra</h5>
              <h5>Anatomy</h5>
              <h5>Yoga Nidra</h5>
              <h5>Mudra Yoga</h5>
              <h5>Satsang</h5>
            </>
          }
        />

        <Collapse
          id="fees"
          heading="What we will covered in fees that you pay us?"
          text={
            <>
              <ul>
                <li>Accomodation</li>
                <li>Meals during the course</li>
                <li>Yoga Manual</li>
                <li>Yoga Kit</li>
                <li>Yoga Material</li>
                <li>Tour And Excursions</li>
                <li>Yoga Classes During The Cours</li>
                <li>Yoga Classes</li>
                <li>Yoga Alliance Certificate</li>
              </ul>
            </>
          }
        />

        <Collapse
          id="pay"
          heading="How Can We Pay?"
          text={
            <>
              You Can Pay Us through Pay Pal And Other Option is Through Bank
              Transfer. For Bank Transfer We Will Provide You Bank Details
              Through Email.
            </>
          }
        />

        <Collapse
          id="Bachsize"
          heading="Batch size of each course "
          text="Min-5 And Max-15 Students in each batch"
        />

        <Collapse
          id="alcohol"
          heading="Is there alcohol allowed?"
          text="No, we don't allowed in the campus or during the course."
        />

        <Collapse
          id="smoking"
          heading="Is smoking allowed during the course?"
          text="In yoga courses we prefer the student should not smoke and we don't allowed them inside the campus."
        />

        <Collapse
          id="exam"
          heading="Do the students needs to give exam?"
          text="Yes! during the course we take practical and written exam in order to certified students."
        />

        <Collapse
          id="certified"
          heading="Which certificate do the student get after complition?"
          text={
            <div>
              <p>
                As we are part of Yoga Alliance and a certified school so the
                students will get the Alliance Certificate.
              </p>
              <p>
                This cerficate and the knowledge will help you and leads you to
                be a certified teacher and you can start your own yoga studio.
              </p>
            </div>
          }
        />

        <Collapse
          id="reach"
          heading="How can we reach Pankaj Yogpeeth?"
          text={
            <div>
              <p>
                Our all the locations are easily rechable if any case you found
                any difficulty or have any questions regarding this we are here
                to help you and our team can pick you from any nearest location
                or airports.
              </p>
            </div>
          }
        />
      </div>
    </div>
  );
}
