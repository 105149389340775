import Styles from "../../css/Custom.module.css";
import { InfoCard } from "./InfoCard";

export function WhyPankaj(props) {
    return (


        <div className={`${Styles.whyPankaj}  ${Styles.mtJumbo}`}>
            <div className={Styles.textContainer}>
                <div className={`${Styles.heading}`}>
                    <h1>
                        <u>
                            {props.heading}
                        </u>
                    </h1>
                </div>
                <div className={`${Styles.mt1} ${Styles.text}`}>
                    {props.text}
                </div>
                <div className={Styles.wrapper}>

                    {props.infoCardData ? props.infoCardData.map((item, index) =>
                        <InfoCard
                            key={index}
                            icon={item[0]}
                            title={item[1]}
                            text={item[2]}
                        />
                    )
                        : ''

                    }


                </div>
            </div>
        </div>
    )
}
