import Styles from "../../css/Custom.module.css";
import { useState } from "react";

export function InfoCard(props) {
  const [showAnswer, setShowAnswer] = useState("hide");
  return (
    <>
      <div className={Styles.bulletinCard}>
        {props.img ? (
          <div className={Styles.imgContainer}>
            <img
              src={props.img}
              alt={props.textTitle}
              title={props.textTitle}
            />
          </div>
        ) : props.icon ? (
          <div className={Styles.icon}> {props.icon} </div>
        ) : (
          ""
        )}

        <div className={`${Styles.bulletinHeading}`}>
          <span>{props.title}</span>
        </div>

        <div className={`${Styles.bulletinText}`}>{props.text}</div>
        {props.answerBtnText ? (
          <div
            className={`${Styles.answerTab} ${Styles.pointer} `}
            onClick={() =>
              setShowAnswer(showAnswer === "show" ? "hide" : "show")
            }
          >
            {showAnswer === "show" ? "View Question" : props.answerBtnText}
          </div>
        ) : (
          ""
        )}
        {showAnswer === "show" ? (
          <div className={Styles.answerCard}>{props.answer}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
