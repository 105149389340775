import Helmet from "react-helmet";

export function MetaRishikesh(props) {
    return (

        <Helmet>

            <meta
                name="geo.region"
                content={
                    props.region ? props.region : `IN-UK`
                } />
            {/* City and State */}

            <meta
                name="geo.placename"
                content={
                    props.placename ? props.placename : `Rishikesh, Uttarakhand`
                } />
            {/* City And State */}

            <title>
                {
                    props.title ? props.title : `Best Yoga School in Rishikesh | Trusted Yoga School`
                }

            </title>

            <meta name="geo.position" content={props.position ? props.position : '30.123618470767628, 78.32271291310317'} />
            {/* latitude; longitude */}

            <meta name="ICBM" content="30.123618470767628, 78.32271291310317" />
            {/* latitude; longitude */}

            <meta
                name="title"
                content={props.title ? props.title : `Best Yoga School in Rishikesh
                        | Pankaj Yogpeeth - Academy`} />

            <meta
                name="description"
                content={props.description ? props.description : `Best Yoga School in Rishikesh
            | Pankaj Yogpeeth - Academy`}
            />

            <meta
                name="msapplication-starturl"
                content="https://www.pankajyogpeeth.com/" />

            <link rel='canonical' href='https://www.pankajyogpeeth.com' />

            <meta
                name="DC.title"
                content={props.description ? props.description : `Best Yoga School in Rishikesh`} />
            <meta
                name="keywords"
                content="
                100 hours yoga teacher training course,
                200 hours yoga teacher training course,
                300 hours yoga teacher training course,
                500 hours yoga teacher training course,
                best yoga institute in Rishikesh,
                best yoga institute in Kerala,
                best yoga institute in Nepal,
                best yoga institute,
                pankaj yogpeeth,
                yoga in Rishikesh,
                yoga in Kerala,
                yoga in Nepal,
                yoga,
                pranayama,
                yoga techer training,
                best yoga institute in Rishikesh,
                best yoga institute in Kerala,
                best yoga institute in Nepal,
                meditation,
                yoga phylosophy,
                yoga in Rishikesh India,
                yoga in Kerala India,
                yoga in Nepal Nepal,
                yoga classes,
                yoga in India,
                yoga institute,
                ashtanga yoga,
                master in yoga,
                master course in yoga,
                yoga for begginer,
                yoga for teahers,
                yoga for seniors,
                yoga for juniors,
                yoga for adults,
                yoga for girls,
                yoga for woman,
                yoga for weman,
                yoga for man,
                yoga for old man,
                yoga for old men,
                yoga for old woman,
                yoga for old weman,
                " />

            <meta
                name="image"
                content={props.imageUrl ? props.imageUrl : ''} />


            <meta itemprop="name"
                content="Yoga School in Rishikesh - Pankaj Yogpeeth" />

            <meta itemprop="description"
                content="Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Rishikesh. Learn yoga with experts with highly educated teachers." />

            <meta itemprop="image"
                content={props.imageUrl ? props.imageUrl : ''} />


            <meta property="og:title"
                content="Best Yoga School in Rishikesh | 100 Hours TTC" />

            <meta property="og:description"
                content="Pankaj Yogpeeth offers Residential 100 Hours, 200 Hours, 300 Hours Yoga TTC in Rishikesh. Learn yoga with experts with highly educated teachers." />

            <meta property="og:image"
                content={props.imageUrl ? props.imageUrl : ''} />

            <meta property="og:url"
                content={props.ogUrl ? props.ogUrl : "https//:www.pankajyogpeeth.com"} />

            <meta property="og:site_name"
                content="Yoga School in Rishikesh - Pankaj Yogpeeth" />


            <meta name="twitter:card"
                content="summary" />

            <meta name="twitter:title"
                content={
                    props.title ? props.title : `Best Yoga School in Rishikesh
                        | Pankaj Yogpeeth - Academy`
                } />

            <meta name="twitter:description"
                content={props.description ? props.description : `Best Yoga School in Rishikesh
            | Pankaj Yogpeeth - Academy`} />

            <meta name="twitter:image:src"
                content={props.imageUrl ? props.imageUrl : 'https://www.pankajyogpeeth.com/images/gallery-memories/13.jpg'} />

        </Helmet>
    )
}
