// import * as React from 'react';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Styles from "../../css/Custom.module.css";
import { useState } from "react";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  {
    img: "./assets/images/pankaj/teachers/pankaj.jpeg",
    title: "Yogacharya Pankaj Ji",
    rows: 2,
    cols: 2,
  },
  {
    img: "./assets/images/pankaj/rishikesh/PankajYogpeeth (13).jpeg",
    title: "yoga in rishikesh",
  },
  {
    img: "./assets/images/pankaj/rishikesh/PankajYogpeeth (3).jpeg",
    title: "Restoring Chakra",
  },
  {
    img: "./assets/images/pankaj/PankajYogpeeth4.jpg",
    title: "Yoga School In Rishikesh",
    cols: 2,
  },
  {
    img: "./assets/images/pankaj/PankajYogpeeth (78).jpg",
    title: "Hats",
    cols: 2,
  },
  {
    img: "./assets/images/pankaj/PankajYogpeeth (71).jpg",
    title: "Best School Of Yoga In Rishikesh",
    author: "yoga",
    rows: 2,
    cols: 2,
  },
  {
    img: "./assets/images/pankaj/Nepal/PankajYogpeeth (15).jpg",
    title: "Best Yoga School In Nepal",
  },
  {
    img: "./assets/images/pankaj/rishikesh/PankajYogpeeth (67).jpg",
    title: "Best Yoga School In Nepal",
  },
  {
    img: "./assets/images/pankaj/PankajYogpeeth (36).jpg",
    title: "Kavita Ji",
    rows: 2,
    cols: 2,
  },
  {
    img: "./assets/images/pankaj/rys/300-teacher-training-course.png",
    title: "Best Yoga School In Nepal",
  },
  {
    img: "./assets/images/pankaj/Nepal/PankajYogpeeth (39).jpg",
    title: "Best Yoga School In Kerala",
  },
  {
    img: "./assets/images/pankaj/Nepal/PankajYogpeeth (73).jpg",
    title: "Bike",
    cols: 2,
  },
];

export function QuiltedImageList() {
  const [imgIndex, setImgIndex] = useState(1);
  const [showCarousel, setShowCarousel] = useState("none");
  const changeArrayVal = (val) => {
    if (val === "sub") {
      setImgIndex(imgIndex > itemData.length - 2 ? 0 : imgIndex + 1);
    } else {
      setImgIndex(imgIndex < 1 ? itemData.length - 1 : imgIndex - 1);
    }
  };

  const imgCarousel = (data) => {
    setImgIndex(data);
    setShowCarousel("flex");
  };

  return (
    <>
      <ImageList
        sx={{ width: "auto", height: "auto" }}
        variant="quilted"
        cols={4}
        className={Styles.height600}
        rowHeight={"auto"}
      >
        {itemData.map((item, index) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt={item.title}
              onClick={() => imgCarousel(index)}
              loading="lazy"
              className={Styles.pointer}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <div className={Styles.carousel} style={{ display: showCarousel }}>
        <div
          className={Styles.cancelBtn}
          onClick={() => setShowCarousel("none")}
        >
          <ImCross />
        </div>

        <div className={Styles.leftArrow} onClick={() => changeArrayVal("add")}>
          <IoChevronBackOutline />
        </div>

        <img src={itemData[imgIndex].img} className="img-fluid" alt="img" />

        <div
          className={Styles.rightArrow}
          onClick={() => changeArrayVal("sub")}
        >
          <IoChevronForwardOutline />
        </div>
      </div>
    </>
  );
}
