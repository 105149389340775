import React from "react";
import style from "../../css/Authentication.module.css";
import { BootstrapModal } from "./BootstrapModal";

function UserProfileCard({
  profilePic,
  username,
  occupation,
  email,
  city,
  country,
  mobile,
}) {
  return (
    <div className={style.userProileCard}>
      <div className={style.profileCardWrapper}>
        <img src={profilePic} alt={username} />
      </div>
      <div className={style.userDetailsWrapper}>
        <span> {username} </span>
        <span> {occupation} </span>
        <span>{email}</span>
        <span>{mobile}</span>
        <div className="d-flex">
          <span> {city} </span>
          <span className="mx-2"> {country} </span>
        </div>
      </div>
      <div
        className={style.viewProfileCard}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Change Or Edit Setting
      </div>

      <BootstrapModal
        modalContent={
          <>
            <div className="form-group mb-2">
              <label htmlFor="username"> Username </label>
              <input
                type="text"
                className="form-control"
                name=""
                id="username"
                roll="input"
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="email"> Email </label>
              <input
                type="email"
                className="form-control"
                name=""
                id="email"
                roll="input"
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="mobile"> Mobile </label>
              <div className="d-flex">
                <input
                  type="number"
                  className="form-control"
                  name=""
                  id="mobile"
                  roll="input"
                />
              </div>
            </div>
            <div className="form-group mb-2">
              <label htmlFor="mobile"> City </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  name=""
                  id="mobile"
                  roll="input"
                />
              </div>
            </div>

            <div className="form-group mb-2">
              <label htmlFor="mobile"> Country </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  name=""
                  id="mobile"
                  roll="input"
                />
              </div>
            </div>
            <div className="mb-2 text-center">
              <button type="button" className="btn">
                {" "}
                Submit{" "}
              </button>
            </div>
          </>
        }
      />
    </div>
  );
}

export default UserProfileCard;
