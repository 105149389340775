import React, { useEffect } from "react";
import { useState } from "react";
import style from "../../css/Authentication.module.css";
import UserProfileCard from "./UserProfileCard";
import { BlogsSort } from "./BlogsSort";
import axios from "axios";
import { PostCard } from "../../components/util/PostCard";

function Profile() {
  const [postData, setPostData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    if (localStorage.getItem("userId")) {
      await axios
        .get(`getBlogs/${localStorage.getItem("userId")}`)
        .then((res) => {
          console.log(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      await axios
        .get("getBlogs")
        .then((res) => {
          console.log(res.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  };

  // localStorage.clear();
  loading ? console.log(loading) : postData.map((item) => console.log(item));

  useEffect(() => {
    getData();
  }, []);

  return loading === true ? (
    "..loading"
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 px-0">
          <div className={style.profileBanner}>
            <img
              src="assets/images/pankaj/jaipur/bestyogaschool.jpg"
              className={style.profileBannerImg}
              alt="bannerName"
            />
          </div>

          {/* User Profile Card */}
          <UserProfileCard
            profilePic="assets/images/chakra/chakra.webp"
            username="Shivam Mittal"
            email="twinkle9997@gmail.com"
            occupation="web developer"
            city="Rishikesh"
            mobile="1234567890"
            country="India"
          />
        </div>
      </div>

      <div className={style.flexWrapper}>
        <div className={style.postCardWrapper}>
          {postData.map((item, index) => (
            <PostCard
              key={index}
              text={item.description}
              img={"http://localhost:8000/blogs/" + item.file}
              profilePic={"assets/images/chakra/chakra.webp"}
              fileType={item.fileType}
              username={"Shivam Mittal"}
              occupation={"Yogi"}
              title={item.subject}
              date={item.created_at}
              postId={item.id}
              userId={item.userId}
              like={localStorage.getItem("userId") ? item.like : ""}
            />
          ))}
        </div>
        <div>
          <BlogsSort />
        </div>
      </div>
    </div>
  );
}

export default Profile;
