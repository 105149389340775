import { NavLink } from "react-router-dom";
import Styles from "../../css/Custom.module.css";
import { FiMapPin } from "react-icons/fi";
import { Callme } from "./Callme";
import { Email } from "./Email";
import {
  BsFacebook,
  BsLinkedin,
  BsInstagram,
  BsTelephone,
} from "react-icons/bs";
import { ImYoutube2 } from "react-icons/im";

export function Footer() {
  const img = "./assets/images/logo/pankajyogapeeth.png";
  const img1 = "./assets/images/logo/pankajyogapeeth.png";

  return (
    <div className="d-flex flex-column">
      <div className={Styles.footer}>
        <div className={Styles.first}>
          <NavLink to="/">
            <video className="img-fluid" controls alt="Yoga In Rishikesh">
              <source src="../assets/images/pankaj/videos/video.mp4" />
            </video>
          </NavLink>
          <p className="mt-2">
            Yoga Alliance certified yoga teacher training school in Rishikesh,
            India at Registered Yoga School Nirvana Yogasthal Rishikesh RYS 100,
            200, 300.
          </p>

          <div className="d-flex">
            <img
              src="../assets/images/pankaj/rys/100-hours-yoga-teacher-training.png"
              className="img-fluid mx-2"
              alt="200 hours yoga techer training course in rishikesh"
            />
            <img
              src="../assets/images/pankaj/rys/200-yoga-teacher-training-in-rishikesh.png"
              className="img-fluid mx-2"
              alt="200 hours yoga techer training course in rishikesh"
            />
          </div>

          <div className="d-flex my-3">
            <img
              src="../assets/images/pankaj/rys/300-yoga-teacher-training-in-rishikesh.png"
              className="img-fluid mx-2"
              alt="300 hours yoga techer training course in rishikesh"
              title="300 hours yoga techer training course in rishikesh"
            />

            <img
              src="../assets/images/pankaj/rys/500.png"
              className="img-fluid mx-2"
              alt="200 hours yoga techer training course in rishikesh"
              title="200 hours yoga techer training course in rishikesh"
            />
          </div>

          <p className=" my-auto">
            <b className="my-auto">
              <FiMapPin /> Address
            </b>
            <p>Ram Jhula, Swarg Ashram, Rishikesh, Uttarakhand 249304</p>
          </p>
        </div>
        <div className={Styles.second}>
          <b>Courses In India</b>

          <b className="mt-2">In Rishikesh</b>
          <NavLink to="/rishikesh/100-hours-yoga-teacher-training-course-in-rishikesh">
            100 Hours TTC In Rishikesh
          </NavLink>
          <NavLink to="/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh">
            200 Hours TTC In Rishikesh
          </NavLink>
          <NavLink to="/rishikesh/300-hours-yoga-teacher-training-course-in-rishikesh">
            300 Hours TTC In Rishikesh
          </NavLink>
          <NavLink to="/rishikesh/500-hours-yoga-teacher-training-course-in-rishikesh">
            500 Hours TTC In Rishikesh
          </NavLink>
          <hr />

          <b className="mt-2">In Kerala</b>
          <NavLink to="/kerala/100-hours-yoga-teacher-training-course-in-kerala">
            100 Hours TTC In Kerala
          </NavLink>
          <NavLink to="/kerala/200-hours-yoga-teacher-training-course-in-kerala">
            200 Hours TTC In Kerala
          </NavLink>
          <NavLink to="/kerala/300-hours-yoga-teacher-training-course-in-kerala">
            300 Hours TTC In Kerala
          </NavLink>
          <NavLink to="/kerala/500-hours-yoga-teacher-training-course-in-kerala">
            500 Hours TTC In Kerala
          </NavLink>

          <hr />
          <b className="mt-2">Courses In Nepal</b>

          <NavLink to="/nepal/100-hours-yoga-teacher-training-course-in-nepal">
            100 Hours TTC In Nepal
          </NavLink>
          <NavLink to="/nepal/200-hours-yoga-teacher-training-course-in-nepal">
            200 Hours TTC In Nepal
          </NavLink>
          <NavLink to="/nepal/300-hours-yoga-teacher-training-course-in-nepal">
            300 Hours TTC In Nepal
          </NavLink>
          <NavLink to="/nepal/500-hours-yoga-teacher-training-course-in-nepal">
            500 Hours TTC In Nepal
          </NavLink>

          <div className="mt-3">
            <div className="mt-2">
              <Callme numb1="+919997777457" />
            </div>
            <div className="mt-2">
              <Callme numb1="+919997402794" />
            </div>
            <div className="mt-2">
              <Email email1="pankajyogpeeth@gmail.com" mailText="none" />
            </div>
            <div className="mt-2">
              <div className={`w-100 d-flex ${Styles.zIndex1}`}>
                <h5 className="mt-2">Follow Us</h5>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://youtube.com/channel/UCCkkqKK9khuApsf-qfB5wcQ"
                  title="Join Us On Facebook"
                >
                  <ImYoutube2 className={`${Styles.icon} mx-2`} />
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/pankajyogpeeth/"
                  title="Join Us On Facebook"
                >
                  <BsFacebook className={`${Styles.icon} mx-2`} />
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href="linkedin.com/in/pankaj-yogpeeth"
                >
                  <BsLinkedin className={`${Styles.icon} mx-2`} />
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/invites/contact/?i=vjacv30t4l9t&utm_content=7v6uhhw"
                >
                  <BsInstagram className={`${Styles.icon} mx-2`} />
                </a>

                <a rel="noreferrer" target="_blank" href="tel:+919997402794">
                  <BsTelephone className={`${Styles.icon} mx-2`} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.third}>
          <b>Important Links</b>
          <div className={`d-flex flex-column ${Styles.links}`}>
            <NavLink to="/teachers" className="mt-2">
              Teachers
            </NavLink>

            <NavLink to="/about-us" className="mt-2">
              About Us
            </NavLink>

            <NavLink to="/contact-us" className="mt-2">
              Contact Us
            </NavLink>

            <span className="mt-2">
              <b>Retreats:-</b>

              <div className="d-flex flex-column">
                <NavLink to="/rishikesh/retreat" className="mt-2">
                  Rishikesh Retreat
                </NavLink>

                <NavLink to="/kerala/retreat" className="mt-2">
                  Kerala Retreat
                </NavLink>

                <NavLink to="/nepal/retreat" className="mt-2">
                  Nepal Retreat
                </NavLink>

                <NavLink to="/sitemap" className="mt-2">
                  Sitemap
                </NavLink>
              </div>
            </span>

            {/* <div className="mt-3 ">
              <b>Comming Soon</b>
              <div className="d-flex flex-row flex-wrap">
                <a rel="noreferrer" target="_blank" className={Styles.disabled}>
                  <img
                    src="../../assets/images/App/available-on-the-app-store.svg"
                    className="mt-2"
                    height="40px"
                    alt="best yoga school in nepal"
                  />
                </a>
                <a
                  target="blank"
                  href="javascript(void:0)"
                  className={Styles.disabled}
                >
                  <img
                    src="../assets/images/App/play-store.svg"
                    className="mt-2"
                    alt="best yoga school in nepal"
                    height="40px"
                    width="150px"
                  />
                </a>
              </div>

              <p className="mt-2">
                Pankaj yogpeeth is one of the best yoga teacher training school
                in Rishikesh. We provide best services and we have heighly
                expericed teachers. You can learn yoga here with clean
                environment.
              </p>
            </div> */}
            <div className="text-center">
              <img
                src="../assets/images/pankaj/rys/300-teacher-training-course.png"
                className="img-fluid mx-auto"
                alt="300 hours yoga techer training course in rishikesh"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-secondary text-white p-1">
        <div className=" d-flex justify-content-between flex-wrap">
          <div className="small">All Rights Are Reserved</div>
          <div className="small">Copywrite &nbsp; &#169; &nbsp; 2022</div>
        </div>

        <div className="small text-center">
          This website design and develop by Shivam Mittal
        </div>

        <div className=" d-flex justify-content-center flex-wrap">
          <div className="small mx-2">
            <Callme
              whatsappText="Can you design a website for me"
              textColor="text-white"
              numb1="+919997077644"
            />
          </div>

          <div className="small mx-2">
            <Email
              textColor="text-white"
              email1="twinkle9997@gmail.com"
              mailText="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
