import { VscCheck } from "react-icons/vsc";
import Styles from "../../css/Custom.module.css";
export function CheckCard(props) {
    return (
        <>
            {props.data.map((item, index) =>
                <div className={`${props.cols ? props.cols : "col-lg-3 col-md-4 col-sm-6 col-12"}  my-2 mx-auto px-4`}
                    key={index} >
                    <div className={`card border-0 shadow ${Styles.hover}`}>
                        <div className="card-body py-2">
                            <div className={Styles.center} style={{ height: props.height ? props.height : '' }}>
                                <VscCheck className="my-auto" />
                                &nbsp;
                                <span> {item} </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}