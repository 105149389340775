import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MultiplePostCard } from "../../components/util/MultiplePostCard";
import { Steppers } from "../../components/util/Steppers";
import FormUploadCard from "./FormUploadCard";

export function Blogs() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  // localStorage.clear();

  return loading ? (
    "...loading"
  ) : (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Steppers
              navlinks={[["/blogs", "Blogs", "best yoga school in nepal"]]}
            />
          </div>
        </div>

        <div className="row mt-5">
          {localStorage.getItem("userId") ? (
            <FormUploadCard />
          ) : (
            <div className="mt-4"></div>
          )}
        </div>

        <div className="row">
          <div className="col-12">
            <MultiplePostCard />
          </div>
        </div>
      </div>
    </div>
  );
}
