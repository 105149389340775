export function Heading(props) {
  const ComponentHeading = {
    fontSize: "calc(20px + 2.5vw)",
    lineHeight: "calc(30px + 2vw)",
    textAlign: "center",
    textTransform: "capitalize",
    color: props.color ? props.color : "var(--golden)",
    marginTop: "40px",
  };
  return (
    <h1 style={ComponentHeading}>
      <b>{props.heading}</b>
    </h1>
  );
}
