import style from "../../css/PostCard.module.css";
const subjects = (event) => {
  const subjectName = event.target.innerText.toLowerCase();
};
export function BlogsSort() {
  return (
    <div className={`d-none d-md-block mt-5 ${style.blogsSortWrapper}`}>
      <div className="card border-0 shadow">
        <div onClick={subjects} className={`card-body`}>
          <div className="d-flex flex-column">
            <b className={`my-1 ${style.pointer}`}> Meditation </b>
            <b className={`my-1 ${style.pointer}`}> Hatha Yoga </b>
            <b className={`my-1 ${style.pointer}`}> Pranayama </b>
          </div>
        </div>
      </div>
    </div>
  );
}
