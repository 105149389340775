import { Banner } from "../../components/util/Banner";
import { RightImg } from "../../components/util/RightImg";
import { Heading } from "../../components/util/Heading";
import { CheckCard } from "../../components/util/CheckCard";
import { MetaForOthers } from "../meta/MetaForOthers";


export function Kerala() {
    return (
        <>
            <MetaForOthers
                title="Retreat in Kerala | Learn Yoga And Enjoy A Week Together | www.pankajyogpeeth.com"
                region="IN-KL"
                placename="Kerala"
                position='9.788839240475891, 76.66260033484168'
                description="Yoga Retreat In Kerala: Enjoy Your Week With Yoga And Healthy Food And Sight Seeing With Accomodation."
            />
            <Banner img="../assets/images/retreat/nepal-retreat123.jpg"
                city={
                    <b>
                        Kerala Retreat
                    </b>
                }
                title="Yoga In Kearala"
            />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto px-0">
                        <Heading heading="Yoga Retreat in Kerala" />
                        <p className="text-center">
                            What does yoga retreat means? A yoga retreat is withdrawal to focus on the practice of yoga. A retreat can be solitary and/or local,but often involves a trip to a location that offers group yoga activities. some retreats around the world will offer you a luxury resorts and places with expensive charges. But with us you will experience the traditional practice of yoga with clean and affordable accomadation, where we will offer you some visitings to ashrams,discover beautiful landscapes and the sunrise in mountains. The retreat will help you to maintain the balance between your mind, body and prana. so, we will offer you a practice of yoga asana,meditation and lots of breath work they will bring the calmness and stillness in your mind.
                        </p>
                    </div>

                    <div className="mt-5">
                        <Heading heading="what do we Include In Retreat?" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/1.webp"
                            title="Yoga Retreat In Kerala"
                            data={<div className="row"> <CheckCard cols="col-12 col-sm-6" data={["Accomodation", "Food", "Traveling", "hearbal Tea", "Yoga Classes", "Sight Seeing", "Pranayam Class", "Meditation Class", "Single Room", "Shared Room"]} /> </div>}
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading="Our Yoga Journey During This Course" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/room.jpg"
                            title="Yoga Retreat In Kerala"
                            data={
                                <div className="px-5">


                                    <p>
                                        Discover Kerala with Pankaj Yogpeeth,
                                        today Kerala has been selected by the National Geographic Traveler as one of the thirteen paradises on the earth. An equable climate, beautiful and serene beaches, peaceful stretch of backwaters and canals, exotic wildlife and lush green hill stations are some of the main attractions of Kerala which make a wonderful place to visit.
                                    </p>
                                    <ul>
                                        <li>
                                            <b>Kerala &nbsp; "God's Own Country"</b>: is the perfect introduction to travel in India. It plays a major role in India tourism and deserves the attentions of tourists from all over the world. There are a lot of tourism options in this culturally and geographically rich state of Kerala, India. During your Kerala tour you will have wonderful opportunity to explore serene backwaters and rolling hills, gushing waterfalls and adrenaline-pumping boat races; ancient culture in modern cities; delectable cuisine and colorful festivals. What else do one need to plan a holiday in Kerala?
                                        </li>
                                        <li>
                                            <b>
                                                The hill stations of Kerala:
                                            </b> are also a superb place for the tourists to visit. It is known for its green forest covers, calm and quiet backwaters, turquoise sea water, incredible sun-kissed beaches, spice plantations, luxuriant forests and vivid therapeutic traditions.
                                        </li>
                                        <li>
                                            <b>
                                                One of Kerala's popular attractions:
                                            </b> is its delicious Kerala cuisine. The cuisines are generally hot and spicy offering great taste and vigour, using plenty of fresh vegetables, exotic fruit, and locally-grown aromatic spices (don’t miss a visit to a spice farm). The Keralites have love in rice and fish.
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                    </div>


                    <div className="mt-5" >
                        <Heading heading=" Most Important - Food" />
                        <RightImg
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/food3.jpg"
                            heading={<h3>
                                <b>
                                    Most Important Thing is food That We Offers To Our Precious Guests:-
                                </b>
                            </h3>}
                            title="Yoga Retreat In Kerala"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Hygienic", "Healthy", "Protene", "Tasty"]}
                                    />
                                    <p className="mt-4">
                                        Food is the most important element in yoga retreat, this is directly related to our physical and mental strength. we constantly expend energy in various yogic activity, so we fill it with the delicious healthy organic food.These things have an effect on the physical and mental activities of the living being. physical health is also important as much as the mental health. We offer delicious vegan and vegetarian meals with lots of healthy food available according to season. we offers fresh juices and seasonal fruits salad.In retreat we also provide the traditional meaLs according to the locations Or the places we are conducting a yoga retreat. we also respect your needs of particular food and will help in arrange with all support.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>

                    <div className="mt-5" >
                        <Heading heading="Stay" />
                        <RightImg
                            dir="rtl"
                            cols="col-12 col-md-5"
                            cols1="col-12 col-md-7"
                            img="../assets/images/retreat/room.jpg"

                            title="Yoga Retreat In Kerala"
                            data={<>
                                <div className="row">
                                    <CheckCard cols="col-12 col-md-6" data={["Clean", "Hygienic", "Single/Shared", "Ac/Non-Ac"]}
                                    />
                                    <p className="mt-4">
                                        HOTEL Sri wellness Charming little hotel located on the South coast of Kerala, It will be our home during this Yoga retreat. Bedrooms surrounded by nature, Yoga hall with wonderful views, massage treatments, outdoor pool, ... they will help us to live with more presence, calm and well-being this stay. Sri Yoga Shala will welcome you to return to yourselves, to discover your essence and connect with it.
                                    </p>
                                </div>
                            </>}
                        />
                    </div>


                </div>

            </div>
        </>
    )
}
