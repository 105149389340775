import { useState, useEffect } from "react";
import axios from "axios";
import style from "../../css/Authentication.module.css";
import { useNavigate } from "react-router-dom";

function Signup() {
  const navigate = new useNavigate();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState();
  const [err, setErr] = useState([]);

  console.log(formData);

  useEffect(() => {
    localStorage.getItem("token") ? navigate("/blogs") : setLoading(false);
  }, [navigate]);

  const formSubmit = () => {
    axios
      .post("register", formData)
      .then(
        (res) => console.log(res)
        // (res.data.status === 200 ? navigate("/login") : "")
      )
      .catch((err) => setErr(err.response.data.errors));
  };

  return loading ? (
    "...loading"
  ) : (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 mt-5">
          <div className={`card ${style.registrationCard} border-0 `}>
            <div className={`${style.logoWrapper} d-flex flex-wrap`}>
              <img
                src="assets/images/pankaj/logo/pankajyogapeeth.png"
                className={`img-fluid ${style.logo}`}
                alt=""
              />
              <h1> Pankaj Yogpeeth </h1>
              <h3> Best Yoga School </h3>
            </div>
            <div className={`${style.formWrapper} position-relative`}>
              <div className="form-group w-100 mb-3">
                <label htmlFor="username" className="text-white">
                  Username
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Your full name please"
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
                {err["name"] && (
                  <span className="small text-danger"> {err["name"]} </span>
                )}
              </div>
              <div className="form-group w-100 mb-3">
                <label htmlFor="email" className="text-white">
                  Email
                </label>
                <input
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="email@gmail.com"
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
                {err["email"] && (
                  <span className="small text-danger"> {err["email"]} </span>
                )}
              </div>
              <div className="form-group w-100 mb-3">
                <label htmlFor="password" className="text-white">
                  Password
                </label>
                <input
                  autoComplete="off"
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="123456789"
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                  security="true"
                />

                {err["password"] && (
                  <span className="small text-danger">{err["password"]}</span>
                )}
              </div>

              <div className="d-flex flex-column">
                <div className="mx-auto">
                  <button
                    className={`btn ${style.cusButton}`}
                    onClick={formSubmit}
                  >
                    Submit
                  </button>
                </div>

                <span className="text-white mt-2">
                  I am already registerd
                  <b
                    className={`text-dark mx-1 ${style.pointer}`}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
