
import Styles from "../../css/Custom.module.css";
import { useEffect } from "react";


export function Sitemap() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="h3 mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 mt-5">
                        <h4 className="mt-4 text-center">
                            Welcome To Pankaj Yogpeeth : All Links Related To Website Is Here
                        </h4>
                    </div>

                    <div className="col-12 mt-4 text-primary">
                        <h3>
                            Important Links
                        </h3>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/" className={`${Styles.siteMapHoverLink}`}>
                            Home
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/contact-us" className={`${Styles.siteMapHoverLink}`}>
                            Contact Us
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/about-us" className={`${Styles.siteMapHoverLink}`}>
                            About Us
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/teachers" className={`${Styles.siteMapHoverLink}`}>
                            Teachers
                        </a>
                    </div>

                    <div className="col-12">
                        <hr />
                    </div>


                    {/* Rishikesh */}
                    <div className="col-12 mt-4  text-primary">
                        <a href="https://pankajyogpeeth.com/rishikesh" className={`${Styles.siteMapHoverLink} text-primary`}>
                            Rishikesh
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/rishikesh/100-hours-yoga-teacher-training-course-in-rishikesh" className={`${Styles.siteMapHoverLink}`}>
                            100 Hours Yoga Teacher Training Course In Rishikesh
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/rishikesh/200-hours-yoga-teacher-training-course-in-rishikesh" className={`${Styles.siteMapHoverLink}`}>
                            200 Hours Yoga Teacher Training Course In Rishikesh
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/rishikesh/300-hours-yoga-teacher-training-course-in-rishikesh" className={`${Styles.siteMapHoverLink}`}>
                            300 Hours Yoga Teacher Training Course In Rishikesh
                        </a>
                    </div>
                    {/* Rishikesh */}

                    <div className="col-12">
                        <hr />
                    </div>

                    {/* kerala */}
                    <div className="col-12 mt-4  text-primary">
                        <a href="https://pankajyogpeeth.com/kerala" className={`${Styles.siteMapHoverLink} text-primary`}>
                            Kerala
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/kerala/100-hours-yoga-teacher-training-course-in-kerala" className={`${Styles.siteMapHoverLink}`}>
                            100 Hours Yoga Teacher Training Course In Kerala
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/kerala/200-hours-yoga-teacher-training-course-in-kerala" className={`${Styles.siteMapHoverLink}`}>
                            200 Hours Yoga Teacher Training Course In Kerala
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/kerala/300-hours-yoga-teacher-training-course-in-kerala" className={`${Styles.siteMapHoverLink}`}>
                            300 Hours Yoga Teacher Training Course In Kerala
                        </a>
                    </div>
                    {/* kerala */}

                    <div className="col-12">
                        <hr />
                    </div>

                    {/* nepal */}
                    <div className="col-12 mt-4  text-primary">
                        <a href="https://pankajyogpeeth.com/nepal" className={`${Styles.siteMapHoverLink} text-primary`}>
                            Nepal
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/nepal/100-hours-yoga-teacher-training-course-in-nepal" className={`${Styles.siteMapHoverLink}`}>
                            100 Hours Yoga Teacher Training Course In Nepal
                        </a>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/nepal/200-hours-yoga-teacher-training-course-in-nepal" className={`${Styles.siteMapHoverLink}`}>
                            200 Hours Yoga Teacher Training Course In Nepal
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 mt-3">
                        <a href="https://pankajyogpeeth.com/nepal/300-hours-yoga-teacher-training-course-in-nepal" className={`${Styles.siteMapHoverLink}`}>
                            300 Hours Yoga Teacher Training Course In Nepal
                        </a>
                    </div>
                    {/* nepal */}


                    <div className="col-12">
                        <hr />
                    </div>

                    {/* Retreat */}
                    <div className="col-12 mt-4  text-primary">
                        <h3 className={`${Styles.siteMapHoverLink} text-primary`}>
                            Our Retreats
                        </h3>
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                        <a href="https://pankajyogpeeth.com/nepal/retreat" className={`${Styles.siteMapHoverLink}`}>
                            Nepal Retreat
                        </a>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                        <a href="https://pankajyogpeeth.com/kerala/retreat" className={`${Styles.siteMapHoverLink}`}>
                            Kerala Retreat
                        </a>
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                        <a href="https://pankajyogpeeth.com/srilanka/retreat" className={`${Styles.siteMapHoverLink}`}>
                            Sri Lanka Retreat
                        </a>
                    </div>
                    {/* nepal */}




                </div>
            </div>
        </div >
    )
}
