import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css.map";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js.map";

import { PageRoutes } from "./project/routes/PageRoutes";
import Manubar from "./project/components/includes/Manubar";
import { Footer } from "./project/components/util/Footer";
import axios from "axios";

axios.defaults.baseURL = "http://localhost:8000/api/";

function App() {
  return (
    <>
      <Manubar />
      <PageRoutes />
      <Footer />
    </>
  );
}

export default App;
